import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';

// FIXME ページによって取得するものをわける 型も定義して
export default function useURLParams() {
  const search = useLocation().search;
  const getParams = useCallback(() => {
    const queryParams = new URLSearchParams(search);
    // if (search.includes('join-room')) {
    const userId = queryParams.get('userId') ?? '';
    const organization_key = queryParams.get('organization_key') ?? '';
    const roomId = queryParams.get('roomId') ?? '';
    const identity = queryParams.get('identity') ?? '';
    const lessonId = queryParams.get('lessonId') ?? '';
    const type = queryParams.get('type') ?? '';
    const group_id = queryParams.get('group_id') ?? '';
    const lesson_frame_id = queryParams.get('lesson_frame_id') ?? '';
    const lang = queryParams.get('lang') ?? 'ja';
    return { userId, organization_key, roomId, identity, lessonId, type, group_id, lesson_frame_id,lang };
    // } else {
    //   return {};
    // }
  }, [search]);
  return getParams;
}
