import { useAppState } from '../../state';

export default function LanguageSelector() {
  const { lessonInfo } = useAppState();
  let systemLanguage = lessonInfo?.lang ? lessonInfo.lang : 'ja';
  let retLangData = '';
  switch (systemLanguage) {
    case 'ja':
      retLangData = require('../../_assets/jsons/lang/ja.json');
      break;
    case 'en':
      retLangData = require('../../_assets/jsons/lang/en.json');
      break;
    case 'vn':
      retLangData = require('../../_assets/jsons/lang/vn.json');
      break;
    case 'ch':
      retLangData = require('../../_assets/jsons/lang/ch.json');
      break;
    default:
      retLangData = require('../../_assets/jsons/lang/ja.json');
  }
  return Object(retLangData);
}
