/* tslint:disable */
/* eslint-disable */
/**
 * くらしスタディAPI仕様
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface AddLessonByTeacherId
 */
export interface AddLessonByTeacherId {
  /**
   *
   * @type {string}
   * @memberof AddLessonByTeacherId
   */
  organization_key: string;
  /**
   *
   * @type {string}
   * @memberof AddLessonByTeacherId
   */
  group_name: string;
  /**
   *
   * @type {number}
   * @memberof AddLessonByTeacherId
   */
  group_id: number;
  /**
   *
   * @type {number}
   * @memberof AddLessonByTeacherId
   */
  lesson_frame_id: number;
  /**
   *
   * @type {string}
   * @memberof AddLessonByTeacherId
   */
  teacher_id: string;
}
/**
 *
 * @export
 * @interface ConnectionId
 */
export interface ConnectionId {
  /**
   *
   * @type {string}
   * @memberof ConnectionId
   */
  room_id: string;
  /**
   *
   * @type {string}
   * @memberof ConnectionId
   */
  connection_id: string;
}
/**
 *
 * @export
 * @interface CreateErrorLog
 */
export interface CreateErrorLog {
  /**
   *
   * @type {string}
   * @memberof CreateErrorLog
   */
  source: string;
  /**
   *
   * @type {string}
   * @memberof CreateErrorLog
   */
  currentPage: string;
  /**
   *
   * @type {string}
   * @memberof CreateErrorLog
   */
  userAgent: string;
  /**
   *
   * @type {string}
   * @memberof CreateErrorLog
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof CreateErrorLog
   */
  clientDate: string;
  /**
   *
   * @type {string}
   * @memberof CreateErrorLog
   */
  errorCode: string;
  /**
   *
   * @type {string}
   * @memberof CreateErrorLog
   */
  error: string;
}
/**
 *
 * @export
 * @interface CreateGroupsInput
 */
export interface CreateGroupsInput {
  /**
   *
   * @type {number}
   * @memberof CreateGroupsInput
   */
  group_id: number;
  /**
   *
   * @type {string}
   * @memberof CreateGroupsInput
   */
  group_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateGroupsInput
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof CreateGroupsInput
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateGroupsInput
   */
  department: string;
  /**
   *
   * @type {string}
   * @memberof CreateGroupsInput
   */
  address: string;
}
/**
 *
 * @export
 * @interface CreateInfoLog
 */
export interface CreateInfoLog {
  /**
   *
   * @type {string}
   * @memberof CreateInfoLog
   */
  source: string;
  /**
   *
   * @type {string}
   * @memberof CreateInfoLog
   */
  currentPage: string;
  /**
   *
   * @type {string}
   * @memberof CreateInfoLog
   */
  userAgent: string;
  /**
   *
   * @type {string}
   * @memberof CreateInfoLog
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof CreateInfoLog
   */
  clientDate: string;
  /**
   *
   * @type {string}
   * @memberof CreateInfoLog
   */
  payload: string;
}
/**
 *
 * @export
 * @interface CreateLearnerInput
 */
export interface CreateLearnerInput {
  /**
   *
   * @type {number}
   * @memberof CreateLearnerInput
   */
  group_id: number;
  /**
   *
   * @type {string}
   * @memberof CreateLearnerInput
   */
  learner_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateLearnerInput
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CreateLearnerInput
   */
  full_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateLearnerInput
   */
  nick_name: string;
}
/**
 *
 * @export
 * @interface CreateLessonEntryRecordInput
 */
export interface CreateLessonEntryRecordInput {
  /**
   *
   * @type {object}
   * @memberof CreateLessonEntryRecordInput
   */
  lesson: object;
}
/**
 *
 * @export
 * @interface CreateLessonFrameInput
 */
export interface CreateLessonFrameInput {
  /**
   *
   * @type {string}
   * @memberof CreateLessonFrameInput
   */
  organization_key: string;
  /**
   *
   * @type {number}
   * @memberof CreateLessonFrameInput
   */
  group_id: number;
  /**
   *
   * @type {number}
   * @memberof CreateLessonFrameInput
   */
  lesson_frame_id?: number;
  /**
   *
   * @type {object}
   * @memberof CreateLessonFrameInput
   */
  delete_flg?: object;
  /**
   *
   * @type {object}
   * @memberof CreateLessonFrameInput
   */
  stop_flg?: object;
  /**
   *
   * @type {number}
   * @memberof CreateLessonFrameInput
   */
  week_number?: number;
  /**
   *
   * @type {string}
   * @memberof CreateLessonFrameInput
   */
  create_datetime?: string;
  /**
   *
   * @type {string}
   * @memberof CreateLessonFrameInput
   */
  update_datetime?: string;
  /**
   *
   * @type {string}
   * @memberof CreateLessonFrameInput
   */
  lesson_day: string;
  /**
   *
   * @type {string}
   * @memberof CreateLessonFrameInput
   */
  start_time: string;
  /**
   *
   * @type {string}
   * @memberof CreateLessonFrameInput
   */
  end_time: string;
  /**
   *
   * @type {string}
   * @memberof CreateLessonFrameInput
   */
  title: string;
  /**
   *
   * @type {number}
   * @memberof CreateLessonFrameInput
   */
  lesson_point: number;
  /**
   *
   * @type {string}
   * @memberof CreateLessonFrameInput
   */
  teacher_id?: string;
  /**
   *
   * @type {number}
   * @memberof CreateLessonFrameInput
   */
  teacher_max_number?: number;
  /**
   *
   * @type {number}
   * @memberof CreateLessonFrameInput
   */
  learner_max_number?: number;
}
/**
 *
 * @export
 * @interface CreateLessonLearnerByLearnerIdInput
 */
export interface CreateLessonLearnerByLearnerIdInput {
  /**
   *
   * @type {string}
   * @memberof CreateLessonLearnerByLearnerIdInput
   */
  organization_key: string;
  /**
   *
   * @type {number}
   * @memberof CreateLessonLearnerByLearnerIdInput
   */
  group_id: number;
  /**
   *
   * @type {number}
   * @memberof CreateLessonLearnerByLearnerIdInput
   */
  lesson_frame_id: number;
  /**
   *
   * @type {number}
   * @memberof CreateLessonLearnerByLearnerIdInput
   */
  lesson_id: number;
  /**
   *
   * @type {number}
   * @memberof CreateLessonLearnerByLearnerIdInput
   */
  lesson_branch_number?: number;
  /**
   *
   * @type {string}
   * @memberof CreateLessonLearnerByLearnerIdInput
   */
  create_datetime?: string;
  /**
   *
   * @type {string}
   * @memberof CreateLessonLearnerByLearnerIdInput
   */
  create_user_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateLessonLearnerByLearnerIdInput
   */
  update_datetime?: string;
  /**
   *
   * @type {string}
   * @memberof CreateLessonLearnerByLearnerIdInput
   */
  update_user_id?: string;
  /**
   *
   * @type {object}
   * @memberof CreateLessonLearnerByLearnerIdInput
   */
  delete_flg?: object;
  /**
   *
   * @type {object}
   * @memberof CreateLessonLearnerByLearnerIdInput
   */
  stop_flg?: object;
  /**
   *
   * @type {string}
   * @memberof CreateLessonLearnerByLearnerIdInput
   */
  learner_id: string;
  /**
   *
   * @type {string}
   * @memberof CreateLessonLearnerByLearnerIdInput
   */
  request_topic: string;
}
/**
 *
 * @export
 * @interface CreateLessonPeriodFramesInput
 */
export interface CreateLessonPeriodFramesInput {
  /**
   *
   * @type {string}
   * @memberof CreateLessonPeriodFramesInput
   */
  organization_key: string;
  /**
   *
   * @type {number}
   * @memberof CreateLessonPeriodFramesInput
   */
  group_id: number;
  /**
   *
   * @type {number}
   * @memberof CreateLessonPeriodFramesInput
   */
  lesson_frame_id?: number;
  /**
   *
   * @type {object}
   * @memberof CreateLessonPeriodFramesInput
   */
  delete_flg?: object;
  /**
   *
   * @type {object}
   * @memberof CreateLessonPeriodFramesInput
   */
  stop_flg?: object;
  /**
   *
   * @type {number}
   * @memberof CreateLessonPeriodFramesInput
   */
  week_number?: number;
  /**
   *
   * @type {string}
   * @memberof CreateLessonPeriodFramesInput
   */
  create_datetime?: string;
  /**
   *
   * @type {string}
   * @memberof CreateLessonPeriodFramesInput
   */
  update_datetime?: string;
  /**
   *
   * @type {string}
   * @memberof CreateLessonPeriodFramesInput
   */
  lesson_day: string;
  /**
   *
   * @type {string}
   * @memberof CreateLessonPeriodFramesInput
   */
  start_time: string;
  /**
   *
   * @type {string}
   * @memberof CreateLessonPeriodFramesInput
   */
  end_time: string;
  /**
   *
   * @type {string}
   * @memberof CreateLessonPeriodFramesInput
   */
  title: string;
  /**
   *
   * @type {number}
   * @memberof CreateLessonPeriodFramesInput
   */
  lesson_point: number;
  /**
   *
   * @type {string}
   * @memberof CreateLessonPeriodFramesInput
   */
  teacher_id?: string;
  /**
   *
   * @type {number}
   * @memberof CreateLessonPeriodFramesInput
   */
  teacher_max_number?: number;
  /**
   *
   * @type {number}
   * @memberof CreateLessonPeriodFramesInput
   */
  learner_max_number?: number;
  /**
   *
   * @type {string}
   * @memberof CreateLessonPeriodFramesInput
   */
  start_period?: string;
  /**
   *
   * @type {string}
   * @memberof CreateLessonPeriodFramesInput
   */
  end_period?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateLessonPeriodFramesInput
   */
  sunday_check?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateLessonPeriodFramesInput
   */
  monday_check?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateLessonPeriodFramesInput
   */
  tuesday_check?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateLessonPeriodFramesInput
   */
  wednesday_check?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateLessonPeriodFramesInput
   */
  thursday_check?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateLessonPeriodFramesInput
   */
  friday_check?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof CreateLessonPeriodFramesInput
   */
  saturday_check?: boolean;
}
/**
 *
 * @export
 * @interface CreateLessonTeacherInput
 */
export interface CreateLessonTeacherInput {
  /**
   *
   * @type {string}
   * @memberof CreateLessonTeacherInput
   */
  organization_key: string;
  /**
   *
   * @type {number}
   * @memberof CreateLessonTeacherInput
   */
  group_id: number;
  /**
   *
   * @type {number}
   * @memberof CreateLessonTeacherInput
   */
  lesson_id: number;
  /**
   *
   * @type {number}
   * @memberof CreateLessonTeacherInput
   */
  lesson_frame_id: number;
  /**
   *
   * @type {number}
   * @memberof CreateLessonTeacherInput
   */
  lesson_branch_number: number;
  /**
   *
   * @type {string}
   * @memberof CreateLessonTeacherInput
   */
  teacher_id: string;
  /**
   *
   * @type {object}
   * @memberof CreateLessonTeacherInput
   */
  delete_flg?: object;
  /**
   *
   * @type {object}
   * @memberof CreateLessonTeacherInput
   */
  stop_flg?: object;
  /**
   *
   * @type {string}
   * @memberof CreateLessonTeacherInput
   */
  create_datetime?: string;
  /**
   *
   * @type {string}
   * @memberof CreateLessonTeacherInput
   */
  update_datetime?: string;
  /**
   *
   * @type {string}
   * @memberof CreateLessonTeacherInput
   */
  create_user_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateLessonTeacherInput
   */
  update_user_id?: string;
}
/**
 *
 * @export
 * @interface CreateNoticeInput
 */
export interface CreateNoticeInput {
  /**
   *
   * @type {string}
   * @memberof CreateNoticeInput
   */
  organization_key: string;
  /**
   *
   * @type {number}
   * @memberof CreateNoticeInput
   */
  notice_id?: number;
  /**
   *
   * @type {number}
   * @memberof CreateNoticeInput
   */
  group_id?: number;
  /**
   *
   * @type {string}
   * @memberof CreateNoticeInput
   */
  create_datetime?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateNoticeInput
   */
  create_user_id?: string;
  /**
   *
   * @type {string}
   * @memberof CreateNoticeInput
   */
  update_datetime?: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateNoticeInput
   */
  update_user_id?: string;
  /**
   *
   * @type {object}
   * @memberof CreateNoticeInput
   */
  delete_flg?: object;
  /**
   *
   * @type {object}
   * @memberof CreateNoticeInput
   */
  stop_flg?: object;
  /**
   *
   * @type {string}
   * @memberof CreateNoticeInput
   */
  start_datetime: string | null;
  /**
   *
   * @type {string}
   * @memberof CreateNoticeInput
   */
  end_datetime: string | null;
  /**
   *
   * @type {number}
   * @memberof CreateNoticeInput
   */
  target: number;
  /**
   *
   * @type {string}
   * @memberof CreateNoticeInput
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof CreateNoticeInput
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof CreateNoticeInput
   */
  notice: string;
}
/**
 *
 * @export
 * @interface CreateOrganizationInput
 */
export interface CreateOrganizationInput {
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationInput
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationInput
   */
  organization_key: string;
}
/**
 *
 * @export
 * @interface CreateOrganizationUserInput
 */
export interface CreateOrganizationUserInput {
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationUserInput
   */
  organization_key: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationUserInput
   */
  full_name: string;
  /**
   *
   * @type {string}
   * @memberof CreateOrganizationUserInput
   */
  email: string;
}
/**
 *
 * @export
 * @interface CreatePointInput
 */
export interface CreatePointInput {
  /**
   *
   * @type {number}
   * @memberof CreatePointInput
   */
  point: number;
  /**
   *
   * @type {number}
   * @memberof CreatePointInput
   */
  paidFlg: number;
}
/**
 *
 * @export
 * @interface CreateRoomChatInput
 */
export interface CreateRoomChatInput {
  /**
   *
   * @type {string}
   * @memberof CreateRoomChatInput
   */
  lesson_id: string;
  /**
   *
   * @type {string}
   * @memberof CreateRoomChatInput
   */
  sender_id: string;
  /**
   *
   * @type {string}
   * @memberof CreateRoomChatInput
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof CreateRoomChatInput
   */
  group_id: string;
  /**
   *
   * @type {string}
   * @memberof CreateRoomChatInput
   */
  lesson_frame_id: string;
}

/**
 *
 * @export
 * @interface CreateTeacherInput
 */
export interface CreateTeacherInput {
  /**
   *
   * @type {string}
   * @memberof CreateTeacherInput
   */
  full_name: string;
}
/**
 *
 * @export
 * @interface LearnerEndpoint
 */
export interface LearnerEndpoint {
  /**
   *
   * @type {string}
   * @memberof LearnerEndpoint
   */
  endpoint: string;
  /**
   *
   * @type {string}
   * @memberof LearnerEndpoint
   */
  webpush_object: string;
  /**
   *
   * @type {string}
   * @memberof LearnerEndpoint
   */
  language_code: string;
  /**
   *
   * @type {string}
   * @memberof LearnerEndpoint
   */
  active_flg: string;
}
/**
 *
 * @export
 * @interface LearnerQuestionnaire
 */
export interface LearnerQuestionnaire {
  /**
   *
   * @type {string}
   * @memberof LearnerQuestionnaire
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof LearnerQuestionnaire
   */
  furigana: string;
  /**
   *
   * @type {string}
   * @memberof LearnerQuestionnaire
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof LearnerQuestionnaire
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof LearnerQuestionnaire
   */
  address: string;
  /**
   *
   * @type {number}
   * @memberof LearnerQuestionnaire
   */
  country_of_origin: number;
  /**
   *
   * @type {string}
   * @memberof LearnerQuestionnaire
   */
  hobby: string;
  /**
   *
   * @type {string}
   * @memberof LearnerQuestionnaire
   */
  residence_years: string;
  /**
   *
   * @type {string}
   * @memberof LearnerQuestionnaire
   */
  n_level: string;
  /**
   *
   * @type {string}
   * @memberof LearnerQuestionnaire
   */
  japanese_opportunity: string;
  /**
   *
   * @type {string}
   * @memberof LearnerQuestionnaire
   */
  purpose: string;
  /**
   *
   * @type {string}
   * @memberof LearnerQuestionnaire
   */
  especially_item: string;
  /**
   *
   * @type {string}
   * @memberof LearnerQuestionnaire
   */
  talk_speed: string;
  /**
   *
   * @type {string}
   * @memberof LearnerQuestionnaire
   */
  indicate: string;
  /**
   *
   * @type {string}
   * @memberof LearnerQuestionnaire
   */
  about_kokoka: string;
}
/**
 *
 * @export
 * @interface PaypayResult
 */
export interface PaypayResult {
  /**
   *
   * @type {string}
   * @memberof PaypayResult
   */
  ShopID: string;
  /**
   *
   * @type {string}
   * @memberof PaypayResult
   */
  OrderID: string;
  /**
   *
   * @type {string}
   * @memberof PaypayResult
   */
  Status: string;
  /**
   *
   * @type {string}
   * @memberof PaypayResult
   */
  TranDate: string;
  /**
   *
   * @type {string}
   * @memberof PaypayResult
   */
  PayPayTrackingID: string;
  /**
   *
   * @type {string}
   * @memberof PaypayResult
   */
  PayPayOrderID: string;
  /**
   *
   * @type {string}
   * @memberof PaypayResult
   */
  CheckString: string;
  /**
   *
   * @type {string}
   * @memberof PaypayResult
   */
  ErrCode: string;
  /**
   *
   * @type {string}
   * @memberof PaypayResult
   */
  ErrInfo: string;
}
/**
 *
 * @export
 * @interface PostTran
 */
export interface PostTran {
  /**
   *
   * @type {number}
   * @memberof PostTran
   */
  amount: number;
  /**
   *
   * @type {object}
   * @memberof PostTran
   */
  itemType: object;
  /**
   *
   * @type {number}
   * @memberof PostTran
   */
  point: number;
  /**
   *
   * @type {object}
   * @memberof PostTran
   */
  paymentType: object;
  /**
   *
   * @type {number}
   * @memberof PostTran
   */
  subscriptionType: number | null;
  /**
   *
   * @type {string}
   * @memberof PostTran
   */
  gmoToken?: string;
  /**
   *
   * @type {string}
   * @memberof PostTran
   */
  itemCode?: string;
}
/**
 *
 * @export
 * @interface PushMsg
 */
export interface PushMsg {
  /**
   *
   * @type {number}
   * @memberof PushMsg
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof PushMsg
   */
  target_type: string;
  /**
   *
   * @type {string}
   * @memberof PushMsg
   */
  target_id: string;
  /**
   *
   * @type {string}
   * @memberof PushMsg
   */
  language_code: string;
  /**
   *
   * @type {string}
   * @memberof PushMsg
   */
  msg_type: string;
  /**
   *
   * @type {string}
   * @memberof PushMsg
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof PushMsg
   */
  send_at: string;
  /**
   *
   * @type {string}
   * @memberof PushMsg
   */
  sent_at: string;
}
/**
 *
 * @export
 * @interface PushMsgLog
 */
export interface PushMsgLog {
  /**
   *
   * @type {number}
   * @memberof PushMsgLog
   */
  push_msg_q_id: number;
  /**
   *
   * @type {string}
   * @memberof PushMsgLog
   */
  learner_id: string;
  /**
   *
   * @type {string}
   * @memberof PushMsgLog
   */
  endpoint: string;
  /**
   *
   * @type {number}
   * @memberof PushMsgLog
   */
  http_result: number;
}
/**
 *
 * @export
 * @interface RegistPointInput
 */
export interface RegistPointInput {
  /**
   *
   * @type {number}
   * @memberof RegistPointInput
   */
  point: number;
  /**
   *
   * @type {number}
   * @memberof RegistPointInput
   */
  myPoint: number;
}
/**
 *
 * @export
 * @interface StudyResult
 */
export interface StudyResult {
  /**
   *
   * @type {string}
   * @memberof StudyResult
   */
  chapter_id: string;
  /**
   *
   * @type {number}
   * @memberof StudyResult
   */
  correct_counts: number;
  /**
   *
   * @type {number}
   * @memberof StudyResult
   */
  total_counts: number;
}
/**
 *
 * @export
 * @interface UpdateLearnerInput
 */
export interface UpdateLearnerInput {
  /**
   *
   * @type {string}
   * @memberof UpdateLearnerInput
   */
  update_user_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateLearnerInput
   */
  update_datetime?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateLearnerInput
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UpdateLearnerInput
   */
  full_name: string;
  /**
   *
   * @type {string}
   * @memberof UpdateLearnerInput
   */
  nick_name: string;
  /**
   *
   * @type {string}
   * @memberof UpdateLearnerInput
   */
  prefectures: string;
  /**
   *
   * @type {string}
   * @memberof UpdateLearnerInput
   */
  municipalities: string;
}
/**
 *
 * @export
 * @interface UpdateLessonLearnerFeedbackInput
 */
export interface UpdateLessonLearnerFeedbackInput {
  /**
   *
   * @type {string}
   * @memberof UpdateLessonLearnerFeedbackInput
   */
  update_datetime?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateLessonLearnerFeedbackInput
   */
  update_user_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateLessonLearnerFeedbackInput
   */
  feed_back: string;
}
/**
 *
 * @export
 * @interface UpdateLessonNoteInput
 */
export interface UpdateLessonNoteInput {
  /**
   *
   * @type {string}
   * @memberof UpdateLessonNoteInput
   */
  update_datetime?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateLessonNoteInput
   */
  update_user_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateLessonNoteInput
   */
  note: string;
}
/**
 *
 * @export
 * @interface UpdateNoticeInput
 */
export interface UpdateNoticeInput {
  /**
   *
   * @type {string}
   * @memberof UpdateNoticeInput
   */
  update_datetime?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateNoticeInput
   */
  update_user_id?: string;
  /**
   *
   * @type {object}
   * @memberof UpdateNoticeInput
   */
  start_datetime: object;
  /**
   *
   * @type {object}
   * @memberof UpdateNoticeInput
   */
  end_datetime: object;
  /**
   *
   * @type {string}
   * @memberof UpdateNoticeInput
   */
  subject: string;
  /**
   *
   * @type {string}
   * @memberof UpdateNoticeInput
   */
  notice: string;
}
/**
 *
 * @export
 * @interface UpdateOrganizationByKeyInput
 */
export interface UpdateOrganizationByKeyInput {
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationByKeyInput
   */
  organization_key: string;
  /**
   *
   * @type {object}
   * @memberof UpdateOrganizationByKeyInput
   */
  delete_flg?: object;
  /**
   *
   * @type {object}
   * @memberof UpdateOrganizationByKeyInput
   */
  stop_flg?: object;
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationByKeyInput
   */
  name?: string;
}
/**
 *
 * @export
 * @interface UpdateOrganizationUserInput
 */
export interface UpdateOrganizationUserInput {
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationUserInput
   */
  organization_key: string;
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationUserInput
   */
  organization_user_id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateOrganizationUserInput
   */
  email?: string;
  /**
   *
   * @type {object}
   * @memberof UpdateOrganizationUserInput
   */
  delete_flg?: object;
  /**
   *
   * @type {object}
   * @memberof UpdateOrganizationUserInput
   */
  stop_flg?: object;
}
/**
 *
 * @export
 * @interface UpdateTeacherBasicInfoInput
 */
export interface UpdateTeacherBasicInfoInput {
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherBasicInfoInput
   */
  full_name: string;
  /**
   *
   * @type {number}
   * @memberof UpdateTeacherBasicInfoInput
   */
  sex: number;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherBasicInfoInput
   */
  nick_name: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherBasicInfoInput
   */
  era_name: string;
  /**
   *
   * @type {number}
   * @memberof UpdateTeacherBasicInfoInput
   */
  birth_yy: number;
  /**
   *
   * @type {number}
   * @memberof UpdateTeacherBasicInfoInput
   */
  birth_mm: number;
  /**
   *
   * @type {number}
   * @memberof UpdateTeacherBasicInfoInput
   */
  birth_dd: number;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherBasicInfoInput
   */
  zip_code: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherBasicInfoInput
   */
  address: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherBasicInfoInput
   */
  address_house: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherBasicInfoInput
   */
  tel_number: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherBasicInfoInput
   */
  fax_number: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherBasicInfoInput
   */
  office_name: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherBasicInfoInput
   */
  post_name: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherBasicInfoInput
   */
  office_tel: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherBasicInfoInput
   */
  office_fax: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherBasicInfoInput
   */
  carries_phone: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherBasicInfoInput
   */
  occupation_id: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherBasicInfoInput
   */
  update_user_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherBasicInfoInput
   */
  update_datetime?: string;
}
/**
 *
 * @export
 * @interface UpdateTeacherBasicPartialInfo
 */
export interface UpdateTeacherBasicPartialInfo {
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherBasicPartialInfo
   */
  nick_name: string;
}
/**
 *
 * @export
 * @interface UpdateTeacherEmailByTeacherId
 */
export interface UpdateTeacherEmailByTeacherId {
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherEmailByTeacherId
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherEmailByTeacherId
   */
  update_user_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherEmailByTeacherId
   */
  update_datetime?: string;
}
/**
 *
 * @export
 * @interface UpdateTeacherLanguageSkillInput
 */
export interface UpdateTeacherLanguageSkillInput {
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherLanguageSkillInput
   */
  language1: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherLanguageSkillInput
   */
  interpreter_level1: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherLanguageSkillInput
   */
  language2: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherLanguageSkillInput
   */
  interpreter_level2: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherLanguageSkillInput
   */
  language3: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherLanguageSkillInput
   */
  interpreter_level3: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherLanguageSkillInput
   */
  language4: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherLanguageSkillInput
   */
  interpreter_level4: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherLanguageSkillInput
   */
  update_user_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherLanguageSkillInput
   */
  update_datetime?: string;
}
/**
 *
 * @export
 * @interface UpdateTeacherQualificationInput
 */
export interface UpdateTeacherQualificationInput {
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherQualificationInput
   */
  qualification1: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherQualificationInput
   */
  acquisition_yy1: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherQualificationInput
   */
  acquisition_mm1: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherQualificationInput
   */
  qualification2: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherQualificationInput
   */
  acquisition_yy2: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherQualificationInput
   */
  acquisition_mm2: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherQualificationInput
   */
  qualification3: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherQualificationInput
   */
  acquisition_yy3: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherQualificationInput
   */
  acquisition_mm3: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherQualificationInput
   */
  qualification4: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherQualificationInput
   */
  acquisition_yy4: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherQualificationInput
   */
  acquisition_mm4: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherQualificationInput
   */
  update_user_id?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateTeacherQualificationInput
   */
  update_datetime?: string;
}

/**
 * APIApi - axios parameter creator
 * @export
 */
export const APIApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {CreateLessonFrameInput} createLessonFrameInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gmoControllerCreateLessonFrame: async (
      organizationKey: string,
      groupId: number,
      createLessonFrameInput: CreateLessonFrameInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('gmoControllerCreateLessonFrame', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('gmoControllerCreateLessonFrame', 'groupId', groupId);
      // verify required parameter 'createLessonFrameInput' is not null or undefined
      assertParamExists('gmoControllerCreateLessonFrame', 'createLessonFrameInput', createLessonFrameInput);
      const localVarPath = `/api/appService/{organizationKey}/group/{groupId}/lessonFrame`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createLessonFrameInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {CreateLessonPeriodFramesInput} createLessonPeriodFramesInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gmoControllerCreatePeriodLessonFrames: async (
      organizationKey: string,
      groupId: number,
      createLessonPeriodFramesInput: CreateLessonPeriodFramesInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('gmoControllerCreatePeriodLessonFrames', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('gmoControllerCreatePeriodLessonFrames', 'groupId', groupId);
      // verify required parameter 'createLessonPeriodFramesInput' is not null or undefined
      assertParamExists(
        'gmoControllerCreatePeriodLessonFrames',
        'createLessonPeriodFramesInput',
        createLessonPeriodFramesInput
      );
      const localVarPath = `/api/appService/{organizationKey}/group/{groupId}/lessonFrames`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createLessonPeriodFramesInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gmoControllerGetPointsHistory: async (
      organizationKey: string,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('gmoControllerGetPointsHistory', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('gmoControllerGetPointsHistory', 'learnerId', learnerId);
      const localVarPath = `/api/appService/gmo/{organizationKey}/{learnerId}/getPointsHistory`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gmoControllerGetValidPoints: async (
      organizationKey: string,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('gmoControllerGetValidPoints', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('gmoControllerGetValidPoints', 'learnerId', learnerId);
      const localVarPath = `/api/appService/gmo/{organizationKey}/{learnerId}/getPoints`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {PaypayResult} paypayResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gmoControllerPostPayPaysales: async (
      organizationKey: string,
      paypayResult: PaypayResult,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('gmoControllerPostPayPaysales', 'organizationKey', organizationKey);
      // verify required parameter 'paypayResult' is not null or undefined
      assertParamExists('gmoControllerPostPayPaysales', 'paypayResult', paypayResult);
      const localVarPath = `/api/appService/gmo/{organizationKey}/paypaySales`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(paypayResult, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {PostTran} postTran
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gmoControllerPostTran: async (
      organizationKey: string,
      postTran: PostTran,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('gmoControllerPostTran', 'organizationKey', organizationKey);
      // verify required parameter 'postTran' is not null or undefined
      assertParamExists('gmoControllerPostTran', 'postTran', postTran);
      const localVarPath = `/api/appService/gmo/{organizationKey}/entryTran`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(postTran, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gmoControllerResultNoticeRecive: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/appService/gmo/resultNotice`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerAppTest: async (version: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'version' is not null or undefined
      assertParamExists('mobileAppControllerAppTest', 'version', version);
      const localVarPath = `/api/appService/master_data`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (version !== undefined) {
        localVarQueryParameter['version'] = version;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} activationCode
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerCheckActivationCode: async (
      organizationKey: string,
      activationCode: string,
      email: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerCheckActivationCode', 'organizationKey', organizationKey);
      // verify required parameter 'activationCode' is not null or undefined
      assertParamExists('mobileAppControllerCheckActivationCode', 'activationCode', activationCode);
      // verify required parameter 'email' is not null or undefined
      assertParamExists('mobileAppControllerCheckActivationCode', 'email', email);
      const localVarPath = `/api/appService/{organizationKey}/activationCode/{activationCode}/{email}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'activationCode'}}`, encodeURIComponent(String(activationCode)))
        .replace(`{${'email'}}`, encodeURIComponent(String(email)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerCheckActivationCodeWithEmail: async (
      organizationKey: string,
      email: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerCheckActivationCodeWithEmail', 'organizationKey', organizationKey);
      // verify required parameter 'email' is not null or undefined
      assertParamExists('mobileAppControllerCheckActivationCodeWithEmail', 'email', email);
      const localVarPath = `/api/appService/{organizationKey}/activationInfo/{email}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'email'}}`, encodeURIComponent(String(email)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {LearnerQuestionnaire} learnerQuestionnaire
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerCreateLearnerQuestionnaire: async (
      organizationKey: string,
      learnerId: string,
      learnerQuestionnaire: LearnerQuestionnaire,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerCreateLearnerQuestionnaire', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('mobileAppControllerCreateLearnerQuestionnaire', 'learnerId', learnerId);
      // verify required parameter 'learnerQuestionnaire' is not null or undefined
      assertParamExists('mobileAppControllerCreateLearnerQuestionnaire', 'learnerQuestionnaire', learnerQuestionnaire);
      const localVarPath = `/api/appService/{organizationKey}/questionnare/{learnerId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(learnerQuestionnaire, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerCreateLearnerQuestionnaireSkip: async (
      organizationKey: string,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerCreateLearnerQuestionnaireSkip', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('mobileAppControllerCreateLearnerQuestionnaireSkip', 'learnerId', learnerId);
      const localVarPath = `/api/appService/{organizationKey}/questionnareSkip/{learnerId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerDeactivateLearnerEndpoints: async (
      organizationKey: string,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerDeactivateLearnerEndpoints', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('mobileAppControllerDeactivateLearnerEndpoints', 'learnerId', learnerId);
      const localVarPath = `/api/appService/{organizationKey}/DeactivateLearnerEndpoints/{learnerId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetIsSubscription: async (organizationKey: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerGetIsSubscription', 'organizationKey', organizationKey);
      const localVarPath = `/api/appService/{organizationKey}/isSubscription`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetLearnerActiveEndpointCount: async (
      organizationKey: string,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerGetLearnerActiveEndpointCount', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('mobileAppControllerGetLearnerActiveEndpointCount', 'learnerId', learnerId);
      const localVarPath = `/api/appService/{organizationKey}/learnerActiveEndpointCount/{learnerId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetLearnerByEmail: async (
      organizationKey: string,
      email: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerGetLearnerByEmail', 'organizationKey', organizationKey);
      // verify required parameter 'email' is not null or undefined
      assertParamExists('mobileAppControllerGetLearnerByEmail', 'email', email);
      const localVarPath = `/api/appService/{organizationKey}/getUserName/{email}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'email'}}`, encodeURIComponent(String(email)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetLearnerEndpoint: async (
      organizationKey: string,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerGetLearnerEndpoint', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('mobileAppControllerGetLearnerEndpoint', 'learnerId', learnerId);
      const localVarPath = `/api/appService/{organizationKey}/learnerEndpoint/{learnerId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetLearnerQuestionnaire: async (
      organizationKey: string,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerGetLearnerQuestionnaire', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('mobileAppControllerGetLearnerQuestionnaire', 'learnerId', learnerId);
      const localVarPath = `/api/appService/{organizationKey}/questionnare/{learnerId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetLearnerQuestionnaireSkip: async (
      organizationKey: string,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerGetLearnerQuestionnaireSkip', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('mobileAppControllerGetLearnerQuestionnaireSkip', 'learnerId', learnerId);
      const localVarPath = `/api/appService/{organizationKey}/questionnareSkip/{learnerId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetLernerRole: async (
      organizationKey: string,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerGetLernerRole', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('mobileAppControllerGetLernerRole', 'learnerId', learnerId);
      const localVarPath = `/api/appService/{organizationKey}/learner/{learnerId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} noticeId
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetNoticeRead: async (
      organizationKey: string,
      noticeId: number,
      userName: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerGetNoticeRead', 'organizationKey', organizationKey);
      // verify required parameter 'noticeId' is not null or undefined
      assertParamExists('mobileAppControllerGetNoticeRead', 'noticeId', noticeId);
      // verify required parameter 'userName' is not null or undefined
      assertParamExists('mobileAppControllerGetNoticeRead', 'userName', userName);
      const localVarPath = `/api/appService/{organizationKey}/noticeRead/{noticeId}/{userName}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'noticeId'}}`, encodeURIComponent(String(noticeId)))
        .replace(`{${'userName'}}`, encodeURIComponent(String(userName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetNoticeUnreadCount: async (
      organizationKey: string,
      userName: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerGetNoticeUnreadCount', 'organizationKey', organizationKey);
      // verify required parameter 'userName' is not null or undefined
      assertParamExists('mobileAppControllerGetNoticeUnreadCount', 'userName', userName);
      const localVarPath = `/api/appService/{organizationKey}/noticeUnreadCount/{userName}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'userName'}}`, encodeURIComponent(String(userName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetPushMsg: async (organizationKey: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerGetPushMsg', 'organizationKey', organizationKey);
      const localVarPath = `/api/appService/{organizationKey}/pushmsg`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} pushMsgQId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetPushMsgLog: async (
      organizationKey: string,
      pushMsgQId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerGetPushMsgLog', 'organizationKey', organizationKey);
      // verify required parameter 'pushMsgQId' is not null or undefined
      assertParamExists('mobileAppControllerGetPushMsgLog', 'pushMsgQId', pushMsgQId);
      const localVarPath = `/api/appService/{organizationKey}/pushmsglog/{pushMsgQId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'pushMsgQId'}}`, encodeURIComponent(String(pushMsgQId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetPushVapidPublicKey: async (
      organizationKey: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerGetPushVapidPublicKey', 'organizationKey', organizationKey);
      const localVarPath = `/api/appService/{organizationKey}/pushVapidPublicKey`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetStudyRecords: async (
      organizationKey: string,
      userName: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerGetStudyRecords', 'organizationKey', organizationKey);
      // verify required parameter 'userName' is not null or undefined
      assertParamExists('mobileAppControllerGetStudyRecords', 'userName', userName);
      const localVarPath = `/api/appService/{organizationKey}/studyRecords/{userName}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'userName'}}`, encodeURIComponent(String(userName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetSubscriptions: async (organizationKey: string, options: any = {}): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerGetSubscriptions', 'organizationKey', organizationKey);
      const localVarPath = `/api/appService/{organizationKey}/subscriptions`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {StudyResult} studyResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerPostStudyRecords: async (
      organizationKey: string,
      studyResult: StudyResult,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerPostStudyRecords', 'organizationKey', organizationKey);
      // verify required parameter 'studyResult' is not null or undefined
      assertParamExists('mobileAppControllerPostStudyRecords', 'studyResult', studyResult);
      const localVarPath = `/api/appService/{organizationKey}/studyRecords`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(studyResult, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} noticeId
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerPutNoticeRead: async (
      organizationKey: string,
      noticeId: number,
      userName: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerPutNoticeRead', 'organizationKey', organizationKey);
      // verify required parameter 'noticeId' is not null or undefined
      assertParamExists('mobileAppControllerPutNoticeRead', 'noticeId', noticeId);
      // verify required parameter 'userName' is not null or undefined
      assertParamExists('mobileAppControllerPutNoticeRead', 'userName', userName);
      const localVarPath = `/api/appService/{organizationKey}/noticeRead/{noticeId}/{userName}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'noticeId'}}`, encodeURIComponent(String(noticeId)))
        .replace(`{${'userName'}}`, encodeURIComponent(String(userName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {ConnectionId} connectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerUpdateLearnerConnectionId: async (
      organizationKey: string,
      learnerId: string,
      connectionId: ConnectionId,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerUpdateLearnerConnectionId', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('mobileAppControllerUpdateLearnerConnectionId', 'learnerId', learnerId);
      // verify required parameter 'connectionId' is not null or undefined
      assertParamExists('mobileAppControllerUpdateLearnerConnectionId', 'connectionId', connectionId);
      const localVarPath = `/api/appService/{organizationKey}/{learnerId}/connectionId`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(connectionId, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerUpdateLearnerEmail: async (
      organizationKey: string,
      learnerId: string,
      email: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerUpdateLearnerEmail', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('mobileAppControllerUpdateLearnerEmail', 'learnerId', learnerId);
      // verify required parameter 'email' is not null or undefined
      assertParamExists('mobileAppControllerUpdateLearnerEmail', 'email', email);
      const localVarPath = `/api/appService/{organizationKey}/{learnerId}/changeEmail/{email}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)))
        .replace(`{${'email'}}`, encodeURIComponent(String(email)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {LearnerEndpoint} learnerEndpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerUpsertLearnerEndpoint: async (
      organizationKey: string,
      learnerId: string,
      learnerEndpoint: LearnerEndpoint,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerUpsertLearnerEndpoint', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('mobileAppControllerUpsertLearnerEndpoint', 'learnerId', learnerId);
      // verify required parameter 'learnerEndpoint' is not null or undefined
      assertParamExists('mobileAppControllerUpsertLearnerEndpoint', 'learnerEndpoint', learnerEndpoint);
      const localVarPath = `/api/appService/{organizationKey}/LearnerEndpoint/{learnerId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(learnerEndpoint, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {PushMsg} pushMsg
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerUpsertPushMsg: async (
      organizationKey: string,
      pushMsg: PushMsg,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerUpsertPushMsg', 'organizationKey', organizationKey);
      // verify required parameter 'pushMsg' is not null or undefined
      assertParamExists('mobileAppControllerUpsertPushMsg', 'pushMsg', pushMsg);
      const localVarPath = `/api/appService/{organizationKey}/pushmsg`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(pushMsg, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {PushMsgLog} pushMsgLog
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerUpsertPushMsgLog: async (
      organizationKey: string,
      pushMsgLog: PushMsgLog,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerUpsertPushMsgLog', 'organizationKey', organizationKey);
      // verify required parameter 'pushMsgLog' is not null or undefined
      assertParamExists('mobileAppControllerUpsertPushMsgLog', 'pushMsgLog', pushMsgLog);
      const localVarPath = `/api/appService/{organizationKey}/pushmsglog`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(pushMsgLog, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerVerifyLearnerEmail: async (
      organizationKey: string,
      learnerId: string,
      email: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('mobileAppControllerVerifyLearnerEmail', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('mobileAppControllerVerifyLearnerEmail', 'learnerId', learnerId);
      // verify required parameter 'email' is not null or undefined
      assertParamExists('mobileAppControllerVerifyLearnerEmail', 'email', email);
      const localVarPath = `/api/appService/{organizationKey}/{learnerId}/verifyEmail/{email}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)))
        .replace(`{${'email'}}`, encodeURIComponent(String(email)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {string} teacherId
     * @param {AddLessonByTeacherId} addLessonByTeacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerAddLessonByTeacherId: async (
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      teacherId: string,
      addLessonByTeacherId: AddLessonByTeacherId,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerAddLessonByTeacherId', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerAddLessonByTeacherId', 'groupId', groupId);
      // verify required parameter 'lessonFrameId' is not null or undefined
      assertParamExists('organizationsControllerAddLessonByTeacherId', 'lessonFrameId', lessonFrameId);
      // verify required parameter 'teacherId' is not null or undefined
      assertParamExists('organizationsControllerAddLessonByTeacherId', 'teacherId', teacherId);
      // verify required parameter 'addLessonByTeacherId' is not null or undefined
      assertParamExists('organizationsControllerAddLessonByTeacherId', 'addLessonByTeacherId', addLessonByTeacherId);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/lessonFrame/{lessonFrameId}/teacher/teacherId/addLesson`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'lessonFrameId'}}`, encodeURIComponent(String(lessonFrameId)))
        .replace(`{${'teacherId'}}`, encodeURIComponent(String(teacherId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(addLessonByTeacherId, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} roomSid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCompleteRoomStatus: async (
      organizationKey: string,
      roomSid: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerCompleteRoomStatus', 'organizationKey', organizationKey);
      // verify required parameter 'roomSid' is not null or undefined
      assertParamExists('organizationsControllerCompleteRoomStatus', 'roomSid', roomSid);
      const localVarPath = `/api/organizations/{organizationKey}/roomSid/{roomSid}/completeRoomStatus`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'roomSid'}}`, encodeURIComponent(String(roomSid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} roomSid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerComposeTwilioVideoRecording: async (
      organizationKey: string,
      roomSid: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerComposeTwilioVideoRecording', 'organizationKey', organizationKey);
      // verify required parameter 'roomSid' is not null or undefined
      assertParamExists('organizationsControllerComposeTwilioVideoRecording', 'roomSid', roomSid);
      const localVarPath = `/api/organizations/{organizationKey}/roomSid/{roomSid}/composeVideoRecording`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'roomSid'}}`, encodeURIComponent(String(roomSid)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateActivationCode: async (
      organizationKey: string,
      groupId: number,
      email: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerCreateActivationCode', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerCreateActivationCode', 'groupId', groupId);
      // verify required parameter 'email' is not null or undefined
      assertParamExists('organizationsControllerCreateActivationCode', 'email', email);
      const localVarPath = `/api/organizations/{organizationKey}/activationCode/{groupId}/{email}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'email'}}`, encodeURIComponent(String(email)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateErrorLog} createErrorLog
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateErrorLog: async (
      createErrorLog: CreateErrorLog,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createErrorLog' is not null or undefined
      assertParamExists('organizationsControllerCreateErrorLog', 'createErrorLog', createErrorLog);
      const localVarPath = `/api/organizations/log/error`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createErrorLog, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {CreateGroupsInput} createGroupsInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateGroup: async (
      organizationKey: string,
      createGroupsInput: CreateGroupsInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerCreateGroup', 'organizationKey', organizationKey);
      // verify required parameter 'createGroupsInput' is not null or undefined
      assertParamExists('organizationsControllerCreateGroup', 'createGroupsInput', createGroupsInput);
      const localVarPath = `/api/organizations/{organizationKey}/group`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createGroupsInput, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateInfoLog} createInfoLog
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateInfoLog: async (
      createInfoLog: CreateInfoLog,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createInfoLog' is not null or undefined
      assertParamExists('organizationsControllerCreateInfoLog', 'createInfoLog', createInfoLog);
      const localVarPath = `/api/organizations/log/info`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createInfoLog, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {CreateLearnerInput} createLearnerInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateLearner: async (
      organizationKey: string,
      groupId: number,
      createLearnerInput: CreateLearnerInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerCreateLearner', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerCreateLearner', 'groupId', groupId);
      // verify required parameter 'createLearnerInput' is not null or undefined
      assertParamExists('organizationsControllerCreateLearner', 'createLearnerInput', createLearnerInput);
      const localVarPath = `/api/organizations/{organizationKey}/groups/{groupId}/learner`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createLearnerInput, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {CreateLearnerInput} createLearnerInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateLearnerFromOrganization: async (
      organizationKey: string,
      groupId: number,
      createLearnerInput: CreateLearnerInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerCreateLearnerFromOrganization', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerCreateLearnerFromOrganization', 'groupId', groupId);
      // verify required parameter 'createLearnerInput' is not null or undefined
      assertParamExists(
        'organizationsControllerCreateLearnerFromOrganization',
        'createLearnerInput',
        createLearnerInput
      );
      const localVarPath = `/api/organizations/{organizationKey}/groups/{groupId}/learnerFromOrganization`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createLearnerInput, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} lessonLogTypeId
     * @param {CreateLessonEntryRecordInput} createLessonEntryRecordInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateLessonEntryRecord: async (
      organizationKey: string,
      lessonLogTypeId: string,
      createLessonEntryRecordInput: CreateLessonEntryRecordInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerCreateLessonEntryRecord', 'organizationKey', organizationKey);
      // verify required parameter 'lessonLogTypeId' is not null or undefined
      assertParamExists('organizationsControllerCreateLessonEntryRecord', 'lessonLogTypeId', lessonLogTypeId);
      // verify required parameter 'createLessonEntryRecordInput' is not null or undefined
      assertParamExists(
        'organizationsControllerCreateLessonEntryRecord',
        'createLessonEntryRecordInput',
        createLessonEntryRecordInput
      );
      const localVarPath = `/api/organizations/{organizationKey}/{lessonLogTypeId}/lessonEntryLog`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'lessonLogTypeId'}}`, encodeURIComponent(String(lessonLogTypeId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createLessonEntryRecordInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {CreateLessonFrameInput} createLessonFrameInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateLessonFrame: async (
      organizationKey: string,
      groupId: number,
      createLessonFrameInput: CreateLessonFrameInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerCreateLessonFrame', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerCreateLessonFrame', 'groupId', groupId);
      // verify required parameter 'createLessonFrameInput' is not null or undefined
      assertParamExists('organizationsControllerCreateLessonFrame', 'createLessonFrameInput', createLessonFrameInput);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/lessonFrame`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createLessonFrameInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} groupId
     * @param {string} learnerId
     * @param {CreateLessonLearnerByLearnerIdInput} createLessonLearnerByLearnerIdInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateLessonLearnerByLearnerId: async (
      organizationKey: string,
      groupId: string,
      learnerId: string,
      createLessonLearnerByLearnerIdInput: CreateLessonLearnerByLearnerIdInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerCreateLessonLearnerByLearnerId', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerCreateLessonLearnerByLearnerId', 'groupId', groupId);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('organizationsControllerCreateLessonLearnerByLearnerId', 'learnerId', learnerId);
      // verify required parameter 'createLessonLearnerByLearnerIdInput' is not null or undefined
      assertParamExists(
        'organizationsControllerCreateLessonLearnerByLearnerId',
        'createLessonLearnerByLearnerIdInput',
        createLessonLearnerByLearnerIdInput
      );
      const localVarPath = `/api/organizations/{organizationKey}/groups/{groupId}/learners/{learnerId}/createLessonLearner`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createLessonLearnerByLearnerIdInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {CreateNoticeInput} createNoticeInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateNotice: async (
      organizationKey: string,
      createNoticeInput: CreateNoticeInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerCreateNotice', 'organizationKey', organizationKey);
      // verify required parameter 'createNoticeInput' is not null or undefined
      assertParamExists('organizationsControllerCreateNotice', 'createNoticeInput', createNoticeInput);
      const localVarPath = `/api/organizations/{organizationKey}/notice`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createNoticeInput, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {CreateOrganizationInput} createOrganizationInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateOrganization: async (
      createOrganizationInput: CreateOrganizationInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createOrganizationInput' is not null or undefined
      assertParamExists(
        'organizationsControllerCreateOrganization',
        'createOrganizationInput',
        createOrganizationInput
      );
      const localVarPath = `/api/organizations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createOrganizationInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {CreateOrganizationUserInput} createOrganizationUserInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateOrganizationUser: async (
      organizationKey: string,
      createOrganizationUserInput: CreateOrganizationUserInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerCreateOrganizationUser', 'organizationKey', organizationKey);
      // verify required parameter 'createOrganizationUserInput' is not null or undefined
      assertParamExists(
        'organizationsControllerCreateOrganizationUser',
        'createOrganizationUserInput',
        createOrganizationUserInput
      );
      const localVarPath = `/api/organizations/{organizationKey}/organizationUsers`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createOrganizationUserInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {CreateTeacherInput} createTeacherInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateTeacher: async (
      organizationKey: string,
      createTeacherInput: CreateTeacherInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerCreateTeacher', 'organizationKey', organizationKey);
      // verify required parameter 'createTeacherInput' is not null or undefined
      assertParamExists('organizationsControllerCreateTeacher', 'createTeacherInput', createTeacherInput);
      const localVarPath = `/api/organizations/{organizationKey}/teacher`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createTeacherInput, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateTeacherFromCSV: async (
      organizationKey: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerCreateTeacherFromCSV', 'organizationKey', organizationKey);
      const localVarPath = `/api/organizations/{organizationKey}/teacher/csv_import`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} avtivationCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerDeleteActivationCode: async (
      organizationKey: string,
      avtivationCode: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerDeleteActivationCode', 'organizationKey', organizationKey);
      // verify required parameter 'avtivationCode' is not null or undefined
      assertParamExists('organizationsControllerDeleteActivationCode', 'avtivationCode', avtivationCode);
      const localVarPath = `/api/organizations/{organizationKey}/activationCode/{avtivation_code}/delete`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'avtivation_code'}}`, encodeURIComponent(String(avtivationCode)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerDeleteGroup: async (
      organizationKey: string,
      groupId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerDeleteGroup', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerDeleteGroup', 'groupId', groupId);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerDeleteLearner: async (
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerDeleteLearner', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerDeleteLearner', 'groupId', groupId);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('organizationsControllerDeleteLearner', 'learnerId', learnerId);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/learner/{learnerId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerDeleteLearnerById: async (
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerDeleteLearnerById', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerDeleteLearnerById', 'groupId', groupId);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('organizationsControllerDeleteLearnerById', 'learnerId', learnerId);
      const localVarPath = `/api/organizations/{organizationKey}/groups/{groupId}/learner/{learnerId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {number} lessonId
     * @param {number} lessonBranchNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerDeleteLesson: async (
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      lessonId: number,
      lessonBranchNumber: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerDeleteLesson', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerDeleteLesson', 'groupId', groupId);
      // verify required parameter 'lessonFrameId' is not null or undefined
      assertParamExists('organizationsControllerDeleteLesson', 'lessonFrameId', lessonFrameId);
      // verify required parameter 'lessonId' is not null or undefined
      assertParamExists('organizationsControllerDeleteLesson', 'lessonId', lessonId);
      // verify required parameter 'lessonBranchNumber' is not null or undefined
      assertParamExists('organizationsControllerDeleteLesson', 'lessonBranchNumber', lessonBranchNumber);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/lessonFrame/{lessonFrameId}/lesson/{lessonId}/lessonBranch/{lessonBranchNumber}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'lessonFrameId'}}`, encodeURIComponent(String(lessonFrameId)))
        .replace(`{${'lessonId'}}`, encodeURIComponent(String(lessonId)))
        .replace(`{${'lessonBranchNumber'}}`, encodeURIComponent(String(lessonBranchNumber)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerDeleteLessonFrame: async (
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerDeleteLessonFrame', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerDeleteLessonFrame', 'groupId', groupId);
      // verify required parameter 'lessonFrameId' is not null or undefined
      assertParamExists('organizationsControllerDeleteLessonFrame', 'lessonFrameId', lessonFrameId);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/lessonFrame/{lessonFrameId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'lessonFrameId'}}`, encodeURIComponent(String(lessonFrameId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} noticeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerDeleteNotice: async (
      organizationKey: string,
      groupId: number,
      noticeId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerDeleteNotice', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerDeleteNotice', 'groupId', groupId);
      // verify required parameter 'noticeId' is not null or undefined
      assertParamExists('organizationsControllerDeleteNotice', 'noticeId', noticeId);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/notice/{noticeId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'noticeId'}}`, encodeURIComponent(String(noticeId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerDeleteOrganization: async (
      organizationKey: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerDeleteOrganization', 'organizationKey', organizationKey);
      const localVarPath = `/api/organizations/{organizationKey}`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerDeleteTeacher: async (
      organizationKey: string,
      teacherId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerDeleteTeacher', 'organizationKey', organizationKey);
      // verify required parameter 'teacherId' is not null or undefined
      assertParamExists('organizationsControllerDeleteTeacher', 'teacherId', teacherId);
      const localVarPath = `/api/organizations/{organizationKey}/teacher/{teacherId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'teacherId'}}`, encodeURIComponent(String(teacherId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} groupId
     * @param {string} lessonFrameId
     * @param {string} lessonId
     * @param {RegistPointInput} registPointInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerEntryLesson: async (
      organizationKey: string,
      groupId: string,
      lessonFrameId: string,
      lessonId: string,
      registPointInput: RegistPointInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerEntryLesson', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerEntryLesson', 'groupId', groupId);
      // verify required parameter 'lessonFrameId' is not null or undefined
      assertParamExists('organizationsControllerEntryLesson', 'lessonFrameId', lessonFrameId);
      // verify required parameter 'lessonId' is not null or undefined
      assertParamExists('organizationsControllerEntryLesson', 'lessonId', lessonId);
      // verify required parameter 'registPointInput' is not null or undefined
      assertParamExists('organizationsControllerEntryLesson', 'registPointInput', registPointInput);
      const localVarPath = `/api/organizations/{organizationKey}/entryLesson/{groupId}/{lessonFrameId}/{lessonId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'lessonFrameId'}}`, encodeURIComponent(String(lessonFrameId)))
        .replace(`{${'lessonId'}}`, encodeURIComponent(String(lessonId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(registPointInput, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetActivationCode: async (
      organizationKey: string,
      groupId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetActivationCode', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerGetActivationCode', 'groupId', groupId);
      const localVarPath = `/api/organizations/{organizationKey}/activationCode/{groupId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {string} nickname
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetCheckNickName: async (
      organizationKey: string,
      teacherId: string,
      nickname: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetCheckNickName', 'organizationKey', organizationKey);
      // verify required parameter 'teacherId' is not null or undefined
      assertParamExists('organizationsControllerGetCheckNickName', 'teacherId', teacherId);
      // verify required parameter 'nickname' is not null or undefined
      assertParamExists('organizationsControllerGetCheckNickName', 'nickname', nickname);
      const localVarPath = `/api/organizations/{organizationKey}/teachersNickName/{teacherId}/{nickname}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'teacherId'}}`, encodeURIComponent(String(teacherId)))
        .replace(`{${'nickname'}}`, encodeURIComponent(String(nickname)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetConnectionIdByRoomId: async (
      organizationKey: string,
      roomId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetConnectionIdByRoomId', 'organizationKey', organizationKey);
      // verify required parameter 'roomId' is not null or undefined
      assertParamExists('organizationsControllerGetConnectionIdByRoomId', 'roomId', roomId);
      const localVarPath = `/api/organizations/{organizationKey}/{roomId}/connectionId`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'roomId'}}`, encodeURIComponent(String(roomId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} groupId
     * @param {string} yyyymm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetCsvContents: async (
      organizationKey: string,
      groupId: string,
      yyyymm: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetCsvContents', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerGetCsvContents', 'groupId', groupId);
      // verify required parameter 'yyyymm' is not null or undefined
      assertParamExists('organizationsControllerGetCsvContents', 'yyyymm', yyyymm);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/csvContents/{yyyymm}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'yyyymm'}}`, encodeURIComponent(String(yyyymm)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetGroupDetail: async (
      organizationKey: string,
      groupId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetGroupDetail', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerGetGroupDetail', 'groupId', groupId);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLearnerPointByLearnerId: async (
      organizationKey: string,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetLearnerPointByLearnerId', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('organizationsControllerGetLearnerPointByLearnerId', 'learnerId', learnerId);
      const localVarPath = `/api/organizations/{organizationKey}/learner/{learnerId}/learnerPoint`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} limit
     * @param {number} page
     * @param {string} searchStatus
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLearnersByOrganizationKey: async (
      organizationKey: string,
      limit: number,
      page: number,
      searchStatus: string,
      searchField: string,
      searchStr: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetLearnersByOrganizationKey', 'organizationKey', organizationKey);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('organizationsControllerGetLearnersByOrganizationKey', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('organizationsControllerGetLearnersByOrganizationKey', 'page', page);
      // verify required parameter 'searchStatus' is not null or undefined
      assertParamExists('organizationsControllerGetLearnersByOrganizationKey', 'searchStatus', searchStatus);
      // verify required parameter 'searchField' is not null or undefined
      assertParamExists('organizationsControllerGetLearnersByOrganizationKey', 'searchField', searchField);
      // verify required parameter 'searchStr' is not null or undefined
      assertParamExists('organizationsControllerGetLearnersByOrganizationKey', 'searchStr', searchStr);
      const localVarPath = `/api/organizations/{organizationKey}/learners`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (searchStatus !== undefined) {
        localVarQueryParameter['search_status'] = searchStatus;
      }

      if (searchField !== undefined) {
        localVarQueryParameter['search_field'] = searchField;
      }

      if (searchStr !== undefined) {
        localVarQueryParameter['search_str'] = searchStr;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLessonByRoomId: async (
      organizationKey: string,
      roomId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetLessonByRoomId', 'organizationKey', organizationKey);
      // verify required parameter 'roomId' is not null or undefined
      assertParamExists('organizationsControllerGetLessonByRoomId', 'roomId', roomId);
      const localVarPath = `/api/organizations/{organizationKey}/roomId/{roomId}/lesson`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'roomId'}}`, encodeURIComponent(String(roomId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {number} lessonId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLessonDetail: async (
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      lessonId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetLessonDetail', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerGetLessonDetail', 'groupId', groupId);
      // verify required parameter 'lessonFrameId' is not null or undefined
      assertParamExists('organizationsControllerGetLessonDetail', 'lessonFrameId', lessonFrameId);
      // verify required parameter 'lessonId' is not null or undefined
      assertParamExists('organizationsControllerGetLessonDetail', 'lessonId', lessonId);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/lessonFrame/{lessonFrameId}/lesson/{lessonId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'lessonFrameId'}}`, encodeURIComponent(String(lessonFrameId)))
        .replace(`{${'lessonId'}}`, encodeURIComponent(String(lessonId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLessonFrameDetail: async (
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetLessonFrameDetail', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerGetLessonFrameDetail', 'groupId', groupId);
      // verify required parameter 'lessonFrameId' is not null or undefined
      assertParamExists('organizationsControllerGetLessonFrameDetail', 'lessonFrameId', lessonFrameId);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/lessonFrame/{lessonFrameId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'lessonFrameId'}}`, encodeURIComponent(String(lessonFrameId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLessonHistoryByTeacherId: async (
      organizationKey: string,
      teacherId: string,
      limit: number,
      page: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetLessonHistoryByTeacherId', 'organizationKey', organizationKey);
      // verify required parameter 'teacherId' is not null or undefined
      assertParamExists('organizationsControllerGetLessonHistoryByTeacherId', 'teacherId', teacherId);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('organizationsControllerGetLessonHistoryByTeacherId', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('organizationsControllerGetLessonHistoryByTeacherId', 'page', page);
      const localVarPath = `/api/organizations/{organizationKey}/teacher/{teacherId}/lessons`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'teacherId'}}`, encodeURIComponent(String(teacherId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLessonHistoryBylearnerId: async (
      organizationKey: string,
      learnerId: string,
      limit: number,
      page: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetLessonHistoryBylearnerId', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('organizationsControllerGetLessonHistoryBylearnerId', 'learnerId', learnerId);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('organizationsControllerGetLessonHistoryBylearnerId', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('organizationsControllerGetLessonHistoryBylearnerId', 'page', page);
      const localVarPath = `/api/organizations/{organizationKey}/learner/{learnerId}/lessons`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} startPeriod
     * @param {string} endPeriod
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLessons: async (
      organizationKey: string,
      startPeriod: string,
      endPeriod: string,
      searchField: string,
      searchStr: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetLessons', 'organizationKey', organizationKey);
      // verify required parameter 'startPeriod' is not null or undefined
      assertParamExists('organizationsControllerGetLessons', 'startPeriod', startPeriod);
      // verify required parameter 'endPeriod' is not null or undefined
      assertParamExists('organizationsControllerGetLessons', 'endPeriod', endPeriod);
      // verify required parameter 'searchField' is not null or undefined
      assertParamExists('organizationsControllerGetLessons', 'searchField', searchField);
      // verify required parameter 'searchStr' is not null or undefined
      assertParamExists('organizationsControllerGetLessons', 'searchStr', searchStr);
      const localVarPath = `/api/organizations/{organizationKey}/lessons`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (startPeriod !== undefined) {
        localVarQueryParameter['startPeriod'] = startPeriod;
      }

      if (endPeriod !== undefined) {
        localVarQueryParameter['endPeriod'] = endPeriod;
      }

      if (searchField !== undefined) {
        localVarQueryParameter['search_field'] = searchField;
      }

      if (searchStr !== undefined) {
        localVarQueryParameter['search_str'] = searchStr;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {string} startPeriod
     * @param {string} endPeriod
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLessonsByLearnerId: async (
      organizationKey: string,
      learnerId: string,
      startPeriod: string,
      endPeriod: string,
      searchField: string,
      searchStr: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetLessonsByLearnerId', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('organizationsControllerGetLessonsByLearnerId', 'learnerId', learnerId);
      // verify required parameter 'startPeriod' is not null or undefined
      assertParamExists('organizationsControllerGetLessonsByLearnerId', 'startPeriod', startPeriod);
      // verify required parameter 'endPeriod' is not null or undefined
      assertParamExists('organizationsControllerGetLessonsByLearnerId', 'endPeriod', endPeriod);
      // verify required parameter 'searchField' is not null or undefined
      assertParamExists('organizationsControllerGetLessonsByLearnerId', 'searchField', searchField);
      // verify required parameter 'searchStr' is not null or undefined
      assertParamExists('organizationsControllerGetLessonsByLearnerId', 'searchStr', searchStr);
      const localVarPath = `/api/organizations/{organizationKey}/learners/{learnerId}/lessons`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (startPeriod !== undefined) {
        localVarQueryParameter['startPeriod'] = startPeriod;
      }

      if (endPeriod !== undefined) {
        localVarQueryParameter['endPeriod'] = endPeriod;
      }

      if (searchField !== undefined) {
        localVarQueryParameter['search_field'] = searchField;
      }

      if (searchStr !== undefined) {
        localVarQueryParameter['search_str'] = searchStr;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLessonsByLessonFrameId: async (
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      limit: number,
      page: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetLessonsByLessonFrameId', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerGetLessonsByLessonFrameId', 'groupId', groupId);
      // verify required parameter 'lessonFrameId' is not null or undefined
      assertParamExists('organizationsControllerGetLessonsByLessonFrameId', 'lessonFrameId', lessonFrameId);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('organizationsControllerGetLessonsByLessonFrameId', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('organizationsControllerGetLessonsByLessonFrameId', 'page', page);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/lessonFrame/{lessonFrameId}/lessons`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'lessonFrameId'}}`, encodeURIComponent(String(lessonFrameId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {string} startPeriod
     * @param {string} endPeriod
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLessonsByTeacherId: async (
      organizationKey: string,
      teacherId: string,
      startPeriod: string,
      endPeriod: string,
      searchField: string,
      searchStr: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetLessonsByTeacherId', 'organizationKey', organizationKey);
      // verify required parameter 'teacherId' is not null or undefined
      assertParamExists('organizationsControllerGetLessonsByTeacherId', 'teacherId', teacherId);
      // verify required parameter 'startPeriod' is not null or undefined
      assertParamExists('organizationsControllerGetLessonsByTeacherId', 'startPeriod', startPeriod);
      // verify required parameter 'endPeriod' is not null or undefined
      assertParamExists('organizationsControllerGetLessonsByTeacherId', 'endPeriod', endPeriod);
      // verify required parameter 'searchField' is not null or undefined
      assertParamExists('organizationsControllerGetLessonsByTeacherId', 'searchField', searchField);
      // verify required parameter 'searchStr' is not null or undefined
      assertParamExists('organizationsControllerGetLessonsByTeacherId', 'searchStr', searchStr);
      const localVarPath = `/api/organizations/{organizationKey}/teachers/{teacherId}/lessons`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'teacherId'}}`, encodeURIComponent(String(teacherId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (startPeriod !== undefined) {
        localVarQueryParameter['startPeriod'] = startPeriod;
      }

      if (endPeriod !== undefined) {
        localVarQueryParameter['endPeriod'] = endPeriod;
      }

      if (searchField !== undefined) {
        localVarQueryParameter['search_field'] = searchField;
      }

      if (searchStr !== undefined) {
        localVarQueryParameter['search_str'] = searchStr;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetMontlyReports: async (
      organizationKey: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetMontlyReports', 'organizationKey', organizationKey);
      const localVarPath = `/api/organizations/{organizationKey}/monthlyReports`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetMostRecentLessonByLearnerId: async (
      organizationKey: string,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetMostRecentLessonByLearnerId', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('organizationsControllerGetMostRecentLessonByLearnerId', 'learnerId', learnerId);
      const localVarPath = `/api/organizations/{organizationKey}/learner/{learnerId}/mostRecentLesson`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetMostRecentLessonByTeacherId: async (
      organizationKey: string,
      teacherId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetMostRecentLessonByTeacherId', 'organizationKey', organizationKey);
      // verify required parameter 'teacherId' is not null or undefined
      assertParamExists('organizationsControllerGetMostRecentLessonByTeacherId', 'teacherId', teacherId);
      const localVarPath = `/api/organizations/{organizationKey}/teacher/{teacherId}/mostRecentLesson`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'teacherId'}}`, encodeURIComponent(String(teacherId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetNotUpdatedNoteLessonsByTeacherId: async (
      organizationKey: string,
      teacherId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists(
        'organizationsControllerGetNotUpdatedNoteLessonsByTeacherId',
        'organizationKey',
        organizationKey
      );
      // verify required parameter 'teacherId' is not null or undefined
      assertParamExists('organizationsControllerGetNotUpdatedNoteLessonsByTeacherId', 'teacherId', teacherId);
      const localVarPath = `/api/organizations/{organizationKey}/teacher/{teacherId}/notUpdatedNoteLessons`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'teacherId'}}`, encodeURIComponent(String(teacherId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} noticeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetNotice: async (
      organizationKey: string,
      noticeId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetNotice', 'organizationKey', organizationKey);
      // verify required parameter 'noticeId' is not null or undefined
      assertParamExists('organizationsControllerGetNotice', 'noticeId', noticeId);
      const localVarPath = `/api/organizations/{organizationKey}/notice/{noticeId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'noticeId'}}`, encodeURIComponent(String(noticeId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} targetNumber
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetNotices: async (
      organizationKey: string,
      targetNumber: number,
      limit: number,
      page: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetNotices', 'organizationKey', organizationKey);
      // verify required parameter 'targetNumber' is not null or undefined
      assertParamExists('organizationsControllerGetNotices', 'targetNumber', targetNumber);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('organizationsControllerGetNotices', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('organizationsControllerGetNotices', 'page', page);
      const localVarPath = `/api/organizations/{organizationKey}/notices/{targetNumber}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'targetNumber'}}`, encodeURIComponent(String(targetNumber)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetOnlyTeacherDataByTeacherId: async (
      organizationKey: string,
      teacherId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetOnlyTeacherDataByTeacherId', 'organizationKey', organizationKey);
      // verify required parameter 'teacherId' is not null or undefined
      assertParamExists('organizationsControllerGetOnlyTeacherDataByTeacherId', 'teacherId', teacherId);
      const localVarPath = `/api/organizations/{organizationKey}/teachersInfo/{teacherId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'teacherId'}}`, encodeURIComponent(String(teacherId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetOrganization: async (
      organizationKey: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetOrganization', 'organizationKey', organizationKey);
      const localVarPath = `/api/organizations/{organizationKey}`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} limit
     * @param {number} page
     * @param {string} searchStatus
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetOrganizationGroups: async (
      organizationKey: string,
      limit: number,
      page: number,
      searchStatus: string,
      searchField: string,
      searchStr: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetOrganizationGroups', 'organizationKey', organizationKey);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('organizationsControllerGetOrganizationGroups', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('organizationsControllerGetOrganizationGroups', 'page', page);
      // verify required parameter 'searchStatus' is not null or undefined
      assertParamExists('organizationsControllerGetOrganizationGroups', 'searchStatus', searchStatus);
      // verify required parameter 'searchField' is not null or undefined
      assertParamExists('organizationsControllerGetOrganizationGroups', 'searchField', searchField);
      // verify required parameter 'searchStr' is not null or undefined
      assertParamExists('organizationsControllerGetOrganizationGroups', 'searchStr', searchStr);
      const localVarPath = `/api/organizations/{organizationKey}/group`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (searchStatus !== undefined) {
        localVarQueryParameter['search_status'] = searchStatus;
      }

      if (searchField !== undefined) {
        localVarQueryParameter['search_field'] = searchField;
      }

      if (searchStr !== undefined) {
        localVarQueryParameter['search_str'] = searchStr;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} limit
     * @param {number} page
     * @param {string} searchStatus
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetOrganizationUsers: async (
      organizationKey: string,
      limit: number,
      page: number,
      searchStatus: string,
      searchField: string,
      searchStr: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetOrganizationUsers', 'organizationKey', organizationKey);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('organizationsControllerGetOrganizationUsers', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('organizationsControllerGetOrganizationUsers', 'page', page);
      // verify required parameter 'searchStatus' is not null or undefined
      assertParamExists('organizationsControllerGetOrganizationUsers', 'searchStatus', searchStatus);
      // verify required parameter 'searchField' is not null or undefined
      assertParamExists('organizationsControllerGetOrganizationUsers', 'searchField', searchField);
      // verify required parameter 'searchStr' is not null or undefined
      assertParamExists('organizationsControllerGetOrganizationUsers', 'searchStr', searchStr);
      const localVarPath = `/api/organizations/{organizationKey}/organizationUsers`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (searchStatus !== undefined) {
        localVarQueryParameter['search_status'] = searchStatus;
      }

      if (searchField !== undefined) {
        localVarQueryParameter['search_field'] = searchField;
      }

      if (searchStr !== undefined) {
        localVarQueryParameter['search_str'] = searchStr;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetOrganizations: async (
      limit: number,
      page: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('organizationsControllerGetOrganizations', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('organizationsControllerGetOrganizations', 'page', page);
      const localVarPath = `/api/organizations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetPushVapidPublicKey: async (
      organizationKey: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetPushVapidPublicKey', 'organizationKey', organizationKey);
      const localVarPath = `/api/organizations/{organizationKey}/pushVapidPublicKey`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetSubscriptionByLearnerId: async (
      organizationKey: string,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetSubscriptionByLearnerId', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('organizationsControllerGetSubscriptionByLearnerId', 'learnerId', learnerId);
      const localVarPath = `/api/organizations/{organizationKey}/learner/{learnerId}/subscription`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetTeacherByTeacherId: async (
      organizationKey: string,
      teacherId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetTeacherByTeacherId', 'organizationKey', organizationKey);
      // verify required parameter 'teacherId' is not null or undefined
      assertParamExists('organizationsControllerGetTeacherByTeacherId', 'teacherId', teacherId);
      const localVarPath = `/api/organizations/{organizationKey}/teacher/{teacherId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'teacherId'}}`, encodeURIComponent(String(teacherId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} limit
     * @param {number} page
     * @param {string} searchStatus
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetTeachersByOrganizationKey: async (
      organizationKey: string,
      limit: number,
      page: number,
      searchStatus: string,
      searchField: string,
      searchStr: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetTeachersByOrganizationKey', 'organizationKey', organizationKey);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('organizationsControllerGetTeachersByOrganizationKey', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('organizationsControllerGetTeachersByOrganizationKey', 'page', page);
      // verify required parameter 'searchStatus' is not null or undefined
      assertParamExists('organizationsControllerGetTeachersByOrganizationKey', 'searchStatus', searchStatus);
      // verify required parameter 'searchField' is not null or undefined
      assertParamExists('organizationsControllerGetTeachersByOrganizationKey', 'searchField', searchField);
      // verify required parameter 'searchStr' is not null or undefined
      assertParamExists('organizationsControllerGetTeachersByOrganizationKey', 'searchStr', searchStr);
      const localVarPath = `/api/organizations/{organizationKey}/teachers`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (searchStatus !== undefined) {
        localVarQueryParameter['search_status'] = searchStatus;
      }

      if (searchField !== undefined) {
        localVarQueryParameter['search_field'] = searchField;
      }

      if (searchStr !== undefined) {
        localVarQueryParameter['search_str'] = searchStr;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} identity
     * @param {string} userId
     * @param {string} roomName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetTwilioAccessToken: async (
      organizationKey: string,
      identity: string,
      userId: string,
      roomName: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetTwilioAccessToken', 'organizationKey', organizationKey);
      // verify required parameter 'identity' is not null or undefined
      assertParamExists('organizationsControllerGetTwilioAccessToken', 'identity', identity);
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('organizationsControllerGetTwilioAccessToken', 'userId', userId);
      // verify required parameter 'roomName' is not null or undefined
      assertParamExists('organizationsControllerGetTwilioAccessToken', 'roomName', roomName);
      const localVarPath = `/api/organizations/{organizationKey}/user/{identity}/{userId}/roomName/{roomName}/getTwilioAccessToken`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'identity'}}`, encodeURIComponent(String(identity)))
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'roomName'}}`, encodeURIComponent(String(roomName)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * roomチャットと登録するユースケース
     * @param {string} organizationKey
     * @param {string} roomId
     * @param {CreateRoomChatInput} createRoomChatInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerPutOrganizationRoomChat: async (
      organizationKey: string,
      roomId: string,
      createRoomChatInput: CreateRoomChatInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerPutOrganizationRoomChat', 'organizationKey', organizationKey);
      // verify required parameter 'roomId' is not null or undefined
      assertParamExists('organizationsControllerPutOrganizationRoomChat', 'roomId', roomId);
      // verify required parameter 'createRoomChatInput' is not null or undefined
      assertParamExists('organizationsControllerPutOrganizationRoomChat', 'createRoomChatInput', createRoomChatInput);
      const localVarPath = `/api/organizations/{organizationKey}/{roomId}/chat`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'roomId'}}`, encodeURIComponent(String(roomId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(createRoomChatInput, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetleanerByGroup: async (
      organizationKey: string,
      groupId: number,
      limit: number,
      page: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetleanerByGroup', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerGetleanerByGroup', 'groupId', groupId);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('organizationsControllerGetleanerByGroup', 'limit', limit);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('organizationsControllerGetleanerByGroup', 'page', page);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/learners`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetleanerByLearnerId: async (
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerGetleanerByLearnerId', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerGetleanerByLearnerId', 'groupId', groupId);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('organizationsControllerGetleanerByLearnerId', 'learnerId', learnerId);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/learner/{learnerId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {CreatePointInput} createPointInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerInsertLearnerPoints: async (
      organizationKey: string,
      learnerId: string,
      createPointInput: CreatePointInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerInsertLearnerPoints', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('organizationsControllerInsertLearnerPoints', 'learnerId', learnerId);
      // verify required parameter 'createPointInput' is not null or undefined
      assertParamExists('organizationsControllerInsertLearnerPoints', 'createPointInput', createPointInput);
      const localVarPath = `/api/organizations/{organizationKey}/learner/{learnerId}/learnerPoint`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createPointInput, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerLessonPermissionOffById: async (
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerLessonPermissionOffById', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerLessonPermissionOffById', 'groupId', groupId);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('organizationsControllerLessonPermissionOffById', 'learnerId', learnerId);
      const localVarPath = `/api/organizations/{organizationKey}/groups/{groupId}/learner/{learnerId}/lesson_permission_off`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerLessonPermissionOnById: async (
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerLessonPermissionOnById', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerLessonPermissionOnById', 'groupId', groupId);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('organizationsControllerLessonPermissionOnById', 'learnerId', learnerId);
      const localVarPath = `/api/organizations/{organizationKey}/groups/{groupId}/learner/{learnerId}/lesson_permission_on`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerResendTeacherInitialPassword: async (
      organizationKey: string,
      email: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerResendTeacherInitialPassword', 'organizationKey', organizationKey);
      // verify required parameter 'email' is not null or undefined
      assertParamExists('organizationsControllerResendTeacherInitialPassword', 'email', email);
      const localVarPath = `/api/organizations/{organizationKey}/resendTeacherInitialPassword/{email}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'email'}}`, encodeURIComponent(String(email)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStartGroup: async (
      organizationKey: string,
      groupId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerStartGroup', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerStartGroup', 'groupId', groupId);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/start`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStartLearnerById: async (
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerStartLearnerById', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerStartLearnerById', 'groupId', groupId);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('organizationsControllerStartLearnerById', 'learnerId', learnerId);
      const localVarPath = `/api/organizations/{organizationKey}/groups/{groupId}/learner/{learnerId}/start`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} noticeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStartNotice: async (
      organizationKey: string,
      groupId: number,
      noticeId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerStartNotice', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerStartNotice', 'groupId', groupId);
      // verify required parameter 'noticeId' is not null or undefined
      assertParamExists('organizationsControllerStartNotice', 'noticeId', noticeId);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/notice/{noticeId}/start`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'noticeId'}}`, encodeURIComponent(String(noticeId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStartOrganizationBykey: async (
      organizationKey: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerStartOrganizationBykey', 'organizationKey', organizationKey);
      const localVarPath = `/api/organizations/{organizationKey}/start`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStartTeacherById: async (
      organizationKey: string,
      teacherId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerStartTeacherById', 'organizationKey', organizationKey);
      // verify required parameter 'teacherId' is not null or undefined
      assertParamExists('organizationsControllerStartTeacherById', 'teacherId', teacherId);
      const localVarPath = `/api/organizations/{organizationKey}/teacher/{teacherId}/start`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'teacherId'}}`, encodeURIComponent(String(teacherId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStopGroup: async (
      organizationKey: string,
      groupId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerStopGroup', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerStopGroup', 'groupId', groupId);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/stop`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStopLearnerById: async (
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerStopLearnerById', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerStopLearnerById', 'groupId', groupId);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('organizationsControllerStopLearnerById', 'learnerId', learnerId);
      const localVarPath = `/api/organizations/{organizationKey}/groups/{groupId}/learner/{learnerId}/stop`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} noticeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStopNotice: async (
      organizationKey: string,
      groupId: number,
      noticeId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerStopNotice', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerStopNotice', 'groupId', groupId);
      // verify required parameter 'noticeId' is not null or undefined
      assertParamExists('organizationsControllerStopNotice', 'noticeId', noticeId);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/notice/{noticeId}/stop`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'noticeId'}}`, encodeURIComponent(String(noticeId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStopOrganizationByKey: async (
      organizationKey: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerStopOrganizationByKey', 'organizationKey', organizationKey);
      const localVarPath = `/api/organizations/{organizationKey}/stop`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStopTeacherById: async (
      organizationKey: string,
      teacherId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerStopTeacherById', 'organizationKey', organizationKey);
      // verify required parameter 'teacherId' is not null or undefined
      assertParamExists('organizationsControllerStopTeacherById', 'teacherId', teacherId);
      const localVarPath = `/api/organizations/{organizationKey}/teacher/{teacherId}/stop`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'teacherId'}}`, encodeURIComponent(String(teacherId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateGroup: async (
      organizationKey: string,
      groupId: number,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerUpdateGroup', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerUpdateGroup', 'groupId', groupId);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {UpdateLearnerInput} updateLearnerInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateLearnerById: async (
      organizationKey: string,
      groupId: number,
      learnerId: string,
      updateLearnerInput: UpdateLearnerInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerUpdateLearnerById', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerUpdateLearnerById', 'groupId', groupId);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('organizationsControllerUpdateLearnerById', 'learnerId', learnerId);
      // verify required parameter 'updateLearnerInput' is not null or undefined
      assertParamExists('organizationsControllerUpdateLearnerById', 'updateLearnerInput', updateLearnerInput);
      const localVarPath = `/api/organizations/{organizationKey}/groups/{groupId}/learner/{learnerId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateLearnerInput, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {ConnectionId} connectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateLearnerConnectionId: async (
      organizationKey: string,
      teacherId: string,
      connectionId: ConnectionId,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerUpdateLearnerConnectionId', 'organizationKey', organizationKey);
      // verify required parameter 'teacherId' is not null or undefined
      assertParamExists('organizationsControllerUpdateLearnerConnectionId', 'teacherId', teacherId);
      // verify required parameter 'connectionId' is not null or undefined
      assertParamExists('organizationsControllerUpdateLearnerConnectionId', 'connectionId', connectionId);
      const localVarPath = `/api/organizations/{organizationKey}/{teacherId}/connectionId`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'teacherId'}}`, encodeURIComponent(String(teacherId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(connectionId, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {number} lessonId
     * @param {CreateLessonTeacherInput} createLessonTeacherInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateLesson: async (
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      lessonId: number,
      createLessonTeacherInput: CreateLessonTeacherInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerUpdateLesson', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerUpdateLesson', 'groupId', groupId);
      // verify required parameter 'lessonFrameId' is not null or undefined
      assertParamExists('organizationsControllerUpdateLesson', 'lessonFrameId', lessonFrameId);
      // verify required parameter 'lessonId' is not null or undefined
      assertParamExists('organizationsControllerUpdateLesson', 'lessonId', lessonId);
      // verify required parameter 'createLessonTeacherInput' is not null or undefined
      assertParamExists('organizationsControllerUpdateLesson', 'createLessonTeacherInput', createLessonTeacherInput);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/lessonFrame/{lessonFrameId}/lesson/{lessonId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'lessonFrameId'}}`, encodeURIComponent(String(lessonFrameId)))
        .replace(`{${'lessonId'}}`, encodeURIComponent(String(lessonId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createLessonTeacherInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {CreateLessonFrameInput} createLessonFrameInput
     * @param {any} [lernerMaxNumber]
     * @param {any} [teacherMaxNumber]
     * @param {any} [title]
     * @param {any} [endTime]
     * @param {any} [startTime]
     * @param {any} [lessonDay]
     * @param {any} [deleteFlg]
     * @param {any} [stopFlg]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateLessonFrame: async (
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      createLessonFrameInput: CreateLessonFrameInput,
      lernerMaxNumber?: any,
      teacherMaxNumber?: any,
      title?: any,
      endTime?: any,
      startTime?: any,
      lessonDay?: any,
      deleteFlg?: any,
      stopFlg?: any,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerUpdateLessonFrame', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerUpdateLessonFrame', 'groupId', groupId);
      // verify required parameter 'lessonFrameId' is not null or undefined
      assertParamExists('organizationsControllerUpdateLessonFrame', 'lessonFrameId', lessonFrameId);
      // verify required parameter 'createLessonFrameInput' is not null or undefined
      assertParamExists('organizationsControllerUpdateLessonFrame', 'createLessonFrameInput', createLessonFrameInput);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/lessonFrame/{lessonFrameId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'lessonFrameId'}}`, encodeURIComponent(String(lessonFrameId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (lernerMaxNumber !== undefined) {
        localVarQueryParameter['lernerMaxNumber'] = lernerMaxNumber;
      }

      if (teacherMaxNumber !== undefined) {
        localVarQueryParameter['teacherMaxNumber'] = teacherMaxNumber;
      }

      if (title !== undefined) {
        localVarQueryParameter['title'] = title;
      }

      if (endTime !== undefined) {
        localVarQueryParameter['endTime'] = endTime;
      }

      if (startTime !== undefined) {
        localVarQueryParameter['startTime'] = startTime;
      }

      if (lessonDay !== undefined) {
        localVarQueryParameter['lessonDay'] = lessonDay;
      }

      if (deleteFlg !== undefined) {
        localVarQueryParameter['deleteFlg'] = deleteFlg;
      }

      if (stopFlg !== undefined) {
        localVarQueryParameter['stopFlg'] = stopFlg;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createLessonFrameInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {number} lessonId
     * @param {number} lessonBranchNumber
     * @param {string} learnerId
     * @param {UpdateLessonLearnerFeedbackInput} updateLessonLearnerFeedbackInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateLessonLearnerFeedback: async (
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      lessonId: number,
      lessonBranchNumber: number,
      learnerId: string,
      updateLessonLearnerFeedbackInput: UpdateLessonLearnerFeedbackInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerUpdateLessonLearnerFeedback', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerUpdateLessonLearnerFeedback', 'groupId', groupId);
      // verify required parameter 'lessonFrameId' is not null or undefined
      assertParamExists('organizationsControllerUpdateLessonLearnerFeedback', 'lessonFrameId', lessonFrameId);
      // verify required parameter 'lessonId' is not null or undefined
      assertParamExists('organizationsControllerUpdateLessonLearnerFeedback', 'lessonId', lessonId);
      // verify required parameter 'lessonBranchNumber' is not null or undefined
      assertParamExists('organizationsControllerUpdateLessonLearnerFeedback', 'lessonBranchNumber', lessonBranchNumber);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('organizationsControllerUpdateLessonLearnerFeedback', 'learnerId', learnerId);
      // verify required parameter 'updateLessonLearnerFeedbackInput' is not null or undefined
      assertParamExists(
        'organizationsControllerUpdateLessonLearnerFeedback',
        'updateLessonLearnerFeedbackInput',
        updateLessonLearnerFeedbackInput
      );
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/lessonFrame/{lessonFrameId}/lesson/{lessonId}/lessonBranchNumber/{lessonBranchNumber}/learnerId/{learnerId}/feed_back`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'lessonFrameId'}}`, encodeURIComponent(String(lessonFrameId)))
        .replace(`{${'lessonId'}}`, encodeURIComponent(String(lessonId)))
        .replace(`{${'lessonBranchNumber'}}`, encodeURIComponent(String(lessonBranchNumber)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateLessonLearnerFeedbackInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {number} lessonId
     * @param {UpdateLessonNoteInput} updateLessonNoteInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateLessonNote: async (
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      lessonId: number,
      updateLessonNoteInput: UpdateLessonNoteInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerUpdateLessonNote', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerUpdateLessonNote', 'groupId', groupId);
      // verify required parameter 'lessonFrameId' is not null or undefined
      assertParamExists('organizationsControllerUpdateLessonNote', 'lessonFrameId', lessonFrameId);
      // verify required parameter 'lessonId' is not null or undefined
      assertParamExists('organizationsControllerUpdateLessonNote', 'lessonId', lessonId);
      // verify required parameter 'updateLessonNoteInput' is not null or undefined
      assertParamExists('organizationsControllerUpdateLessonNote', 'updateLessonNoteInput', updateLessonNoteInput);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/lessonFrame/{lessonFrameId}/lesson/{lessonId}/note`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'lessonFrameId'}}`, encodeURIComponent(String(lessonFrameId)))
        .replace(`{${'lessonId'}}`, encodeURIComponent(String(lessonId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateLessonNoteInput, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} noticeId
     * @param {UpdateNoticeInput} updateNoticeInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateNotice: async (
      organizationKey: string,
      groupId: number,
      noticeId: number,
      updateNoticeInput: UpdateNoticeInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerUpdateNotice', 'organizationKey', organizationKey);
      // verify required parameter 'groupId' is not null or undefined
      assertParamExists('organizationsControllerUpdateNotice', 'groupId', groupId);
      // verify required parameter 'noticeId' is not null or undefined
      assertParamExists('organizationsControllerUpdateNotice', 'noticeId', noticeId);
      // verify required parameter 'updateNoticeInput' is not null or undefined
      assertParamExists('organizationsControllerUpdateNotice', 'updateNoticeInput', updateNoticeInput);
      const localVarPath = `/api/organizations/{organizationKey}/group/{groupId}/notice/{noticeId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'groupId'}}`, encodeURIComponent(String(groupId)))
        .replace(`{${'noticeId'}}`, encodeURIComponent(String(noticeId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(updateNoticeInput, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {UpdateOrganizationByKeyInput} updateOrganizationByKeyInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateOrganization: async (
      organizationKey: string,
      updateOrganizationByKeyInput: UpdateOrganizationByKeyInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerUpdateOrganization', 'organizationKey', organizationKey);
      // verify required parameter 'updateOrganizationByKeyInput' is not null or undefined
      assertParamExists(
        'organizationsControllerUpdateOrganization',
        'updateOrganizationByKeyInput',
        updateOrganizationByKeyInput
      );
      const localVarPath = `/api/organizations/{organizationKey}`.replace(
        `{${'organizationKey'}}`,
        encodeURIComponent(String(organizationKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateOrganizationByKeyInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} organizationUser
     * @param {UpdateOrganizationUserInput} updateOrganizationUserInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateOrganizationUser: async (
      organizationKey: string,
      organizationUser: string,
      updateOrganizationUserInput: UpdateOrganizationUserInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerUpdateOrganizationUser', 'organizationKey', organizationKey);
      // verify required parameter 'organizationUser' is not null or undefined
      assertParamExists('organizationsControllerUpdateOrganizationUser', 'organizationUser', organizationUser);
      // verify required parameter 'updateOrganizationUserInput' is not null or undefined
      assertParamExists(
        'organizationsControllerUpdateOrganizationUser',
        'updateOrganizationUserInput',
        updateOrganizationUserInput
      );
      const localVarPath = `/api/organizations/{organizationKey}/{organizationUser}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'organizationUser'}}`, encodeURIComponent(String(organizationUser)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateOrganizationUserInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {CreateTeacherInput} createTeacherInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateTeacher: async (
      organizationKey: string,
      teacherId: string,
      createTeacherInput: CreateTeacherInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerUpdateTeacher', 'organizationKey', organizationKey);
      // verify required parameter 'teacherId' is not null or undefined
      assertParamExists('organizationsControllerUpdateTeacher', 'teacherId', teacherId);
      // verify required parameter 'createTeacherInput' is not null or undefined
      assertParamExists('organizationsControllerUpdateTeacher', 'createTeacherInput', createTeacherInput);
      const localVarPath = `/api/organizations/{organizationKey}/teacher/{teacherId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'teacherId'}}`, encodeURIComponent(String(teacherId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createTeacherInput, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {UpdateTeacherBasicInfoInput} updateTeacherBasicInfoInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateTeacherBasicInfoByTeacherId: async (
      organizationKey: string,
      teacherId: string,
      updateTeacherBasicInfoInput: UpdateTeacherBasicInfoInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerUpdateTeacherBasicInfoByTeacherId', 'organizationKey', organizationKey);
      // verify required parameter 'teacherId' is not null or undefined
      assertParamExists('organizationsControllerUpdateTeacherBasicInfoByTeacherId', 'teacherId', teacherId);
      // verify required parameter 'updateTeacherBasicInfoInput' is not null or undefined
      assertParamExists(
        'organizationsControllerUpdateTeacherBasicInfoByTeacherId',
        'updateTeacherBasicInfoInput',
        updateTeacherBasicInfoInput
      );
      const localVarPath = `/api/organizations/{organizationKey}/teacher/{teacherId}/updateBasicInfo`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'teacherId'}}`, encodeURIComponent(String(teacherId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTeacherBasicInfoInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {UpdateTeacherEmailByTeacherId} updateTeacherEmailByTeacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateTeacherEmailByTeacherId: async (
      organizationKey: string,
      teacherId: string,
      updateTeacherEmailByTeacherId: UpdateTeacherEmailByTeacherId,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerUpdateTeacherEmailByTeacherId', 'organizationKey', organizationKey);
      // verify required parameter 'teacherId' is not null or undefined
      assertParamExists('organizationsControllerUpdateTeacherEmailByTeacherId', 'teacherId', teacherId);
      // verify required parameter 'updateTeacherEmailByTeacherId' is not null or undefined
      assertParamExists(
        'organizationsControllerUpdateTeacherEmailByTeacherId',
        'updateTeacherEmailByTeacherId',
        updateTeacherEmailByTeacherId
      );
      const localVarPath = `/api/organizations/{organizationKey}/teacher/{teacherId}/updateEmail`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'teacherId'}}`, encodeURIComponent(String(teacherId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTeacherEmailByTeacherId,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {UpdateTeacherLanguageSkillInput} updateTeacherLanguageSkillInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateTeacherLanguageSkillByTeacherId: async (
      organizationKey: string,
      teacherId: string,
      updateTeacherLanguageSkillInput: UpdateTeacherLanguageSkillInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists(
        'organizationsControllerUpdateTeacherLanguageSkillByTeacherId',
        'organizationKey',
        organizationKey
      );
      // verify required parameter 'teacherId' is not null or undefined
      assertParamExists('organizationsControllerUpdateTeacherLanguageSkillByTeacherId', 'teacherId', teacherId);
      // verify required parameter 'updateTeacherLanguageSkillInput' is not null or undefined
      assertParamExists(
        'organizationsControllerUpdateTeacherLanguageSkillByTeacherId',
        'updateTeacherLanguageSkillInput',
        updateTeacherLanguageSkillInput
      );
      const localVarPath = `/api/organizations/{organizationKey}/teacher/{teacherId}/updateLanguageSkill`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'teacherId'}}`, encodeURIComponent(String(teacherId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTeacherLanguageSkillInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {UpdateTeacherBasicPartialInfo} updateTeacherBasicPartialInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateTeacherNickNameByTeacherId: async (
      organizationKey: string,
      teacherId: string,
      updateTeacherBasicPartialInfo: UpdateTeacherBasicPartialInfo,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerUpdateTeacherNickNameByTeacherId', 'organizationKey', organizationKey);
      // verify required parameter 'teacherId' is not null or undefined
      assertParamExists('organizationsControllerUpdateTeacherNickNameByTeacherId', 'teacherId', teacherId);
      // verify required parameter 'updateTeacherBasicPartialInfo' is not null or undefined
      assertParamExists(
        'organizationsControllerUpdateTeacherNickNameByTeacherId',
        'updateTeacherBasicPartialInfo',
        updateTeacherBasicPartialInfo
      );
      const localVarPath = `/api/organizations/{organizationKey}/teacherNickName/{teacherId}/updateBasicInfo`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'teacherId'}}`, encodeURIComponent(String(teacherId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTeacherBasicPartialInfo,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {UpdateTeacherQualificationInput} updateTeacherQualificationInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateTeacherQualificationByTeacherId: async (
      organizationKey: string,
      teacherId: string,
      updateTeacherQualificationInput: UpdateTeacherQualificationInput,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists(
        'organizationsControllerUpdateTeacherQualificationByTeacherId',
        'organizationKey',
        organizationKey
      );
      // verify required parameter 'teacherId' is not null or undefined
      assertParamExists('organizationsControllerUpdateTeacherQualificationByTeacherId', 'teacherId', teacherId);
      // verify required parameter 'updateTeacherQualificationInput' is not null or undefined
      assertParamExists(
        'organizationsControllerUpdateTeacherQualificationByTeacherId',
        'updateTeacherQualificationInput',
        updateTeacherQualificationInput
      );
      const localVarPath = `/api/organizations/{organizationKey}/teacher/{teacherId}/updateQualification`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'teacherId'}}`, encodeURIComponent(String(teacherId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTeacherQualificationInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerWithdrawLearner: async (
      organizationKey: string,
      learnerId: string,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'organizationKey' is not null or undefined
      assertParamExists('organizationsControllerWithdrawLearner', 'organizationKey', organizationKey);
      // verify required parameter 'learnerId' is not null or undefined
      assertParamExists('organizationsControllerWithdrawLearner', 'learnerId', learnerId);
      const localVarPath = `/api/organizations/{organizationKey}/witdraw/{learnerId}`
        .replace(`{${'organizationKey'}}`, encodeURIComponent(String(organizationKey)))
        .replace(`{${'learnerId'}}`, encodeURIComponent(String(learnerId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication JWT-auth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sandboxControllerCognitoSandbox: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/sandbox/cognito`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sandboxControllerMailSandbox: async (options: any = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/sandbox/mail`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * APIApi - functional programming interface
 * @export
 */
export const APIApiFp = function(configuration?: Configuration) {
  const localVarAxiosParamCreator = APIApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {CreateLessonFrameInput} createLessonFrameInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gmoControllerCreateLessonFrame(
      organizationKey: string,
      groupId: number,
      createLessonFrameInput: CreateLessonFrameInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gmoControllerCreateLessonFrame(
        organizationKey,
        groupId,
        createLessonFrameInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {CreateLessonPeriodFramesInput} createLessonPeriodFramesInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gmoControllerCreatePeriodLessonFrames(
      organizationKey: string,
      groupId: number,
      createLessonPeriodFramesInput: CreateLessonPeriodFramesInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gmoControllerCreatePeriodLessonFrames(
        organizationKey,
        groupId,
        createLessonPeriodFramesInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gmoControllerGetPointsHistory(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gmoControllerGetPointsHistory(
        organizationKey,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gmoControllerGetValidPoints(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gmoControllerGetValidPoints(
        organizationKey,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {PaypayResult} paypayResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gmoControllerPostPayPaysales(
      organizationKey: string,
      paypayResult: PaypayResult,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gmoControllerPostPayPaysales(
        organizationKey,
        paypayResult,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {PostTran} postTran
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gmoControllerPostTran(
      organizationKey: string,
      postTran: PostTran,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gmoControllerPostTran(
        organizationKey,
        postTran,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gmoControllerResultNoticeRecive(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gmoControllerResultNoticeRecive(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerAppTest(
      version: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerAppTest(version, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} activationCode
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerCheckActivationCode(
      organizationKey: string,
      activationCode: string,
      email: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerCheckActivationCode(
        organizationKey,
        activationCode,
        email,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerCheckActivationCodeWithEmail(
      organizationKey: string,
      email: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerCheckActivationCodeWithEmail(
        organizationKey,
        email,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {LearnerQuestionnaire} learnerQuestionnaire
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerCreateLearnerQuestionnaire(
      organizationKey: string,
      learnerId: string,
      learnerQuestionnaire: LearnerQuestionnaire,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerCreateLearnerQuestionnaire(
        organizationKey,
        learnerId,
        learnerQuestionnaire,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerCreateLearnerQuestionnaireSkip(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerCreateLearnerQuestionnaireSkip(
        organizationKey,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerDeactivateLearnerEndpoints(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerDeactivateLearnerEndpoints(
        organizationKey,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerGetIsSubscription(
      organizationKey: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetIsSubscription(
        organizationKey,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerGetLearnerActiveEndpointCount(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetLearnerActiveEndpointCount(
        organizationKey,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerGetLearnerByEmail(
      organizationKey: string,
      email: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetLearnerByEmail(
        organizationKey,
        email,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerGetLearnerEndpoint(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetLearnerEndpoint(
        organizationKey,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerGetLearnerQuestionnaire(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetLearnerQuestionnaire(
        organizationKey,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerGetLearnerQuestionnaireSkip(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetLearnerQuestionnaireSkip(
        organizationKey,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerGetLernerRole(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetLernerRole(
        organizationKey,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} noticeId
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerGetNoticeRead(
      organizationKey: string,
      noticeId: number,
      userName: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetNoticeRead(
        organizationKey,
        noticeId,
        userName,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerGetNoticeUnreadCount(
      organizationKey: string,
      userName: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetNoticeUnreadCount(
        organizationKey,
        userName,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerGetPushMsg(
      organizationKey: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetPushMsg(organizationKey, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} pushMsgQId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerGetPushMsgLog(
      organizationKey: string,
      pushMsgQId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetPushMsgLog(
        organizationKey,
        pushMsgQId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerGetPushVapidPublicKey(
      organizationKey: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetPushVapidPublicKey(
        organizationKey,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerGetStudyRecords(
      organizationKey: string,
      userName: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetStudyRecords(
        organizationKey,
        userName,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerGetSubscriptions(
      organizationKey: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerGetSubscriptions(
        organizationKey,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {StudyResult} studyResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerPostStudyRecords(
      organizationKey: string,
      studyResult: StudyResult,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerPostStudyRecords(
        organizationKey,
        studyResult,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} noticeId
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerPutNoticeRead(
      organizationKey: string,
      noticeId: number,
      userName: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerPutNoticeRead(
        organizationKey,
        noticeId,
        userName,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {ConnectionId} connectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerUpdateLearnerConnectionId(
      organizationKey: string,
      learnerId: string,
      connectionId: ConnectionId,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerUpdateLearnerConnectionId(
        organizationKey,
        learnerId,
        connectionId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerUpdateLearnerEmail(
      organizationKey: string,
      learnerId: string,
      email: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerUpdateLearnerEmail(
        organizationKey,
        learnerId,
        email,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {LearnerEndpoint} learnerEndpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerUpsertLearnerEndpoint(
      organizationKey: string,
      learnerId: string,
      learnerEndpoint: LearnerEndpoint,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerUpsertLearnerEndpoint(
        organizationKey,
        learnerId,
        learnerEndpoint,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {PushMsg} pushMsg
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerUpsertPushMsg(
      organizationKey: string,
      pushMsg: PushMsg,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerUpsertPushMsg(
        organizationKey,
        pushMsg,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {PushMsgLog} pushMsgLog
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerUpsertPushMsgLog(
      organizationKey: string,
      pushMsgLog: PushMsgLog,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerUpsertPushMsgLog(
        organizationKey,
        pushMsgLog,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async mobileAppControllerVerifyLearnerEmail(
      organizationKey: string,
      learnerId: string,
      email: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.mobileAppControllerVerifyLearnerEmail(
        organizationKey,
        learnerId,
        email,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {string} teacherId
     * @param {AddLessonByTeacherId} addLessonByTeacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerAddLessonByTeacherId(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      teacherId: string,
      addLessonByTeacherId: AddLessonByTeacherId,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerAddLessonByTeacherId(
        organizationKey,
        groupId,
        lessonFrameId,
        teacherId,
        addLessonByTeacherId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} roomSid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerCompleteRoomStatus(
      organizationKey: string,
      roomSid: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCompleteRoomStatus(
        organizationKey,
        roomSid,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} roomSid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerComposeTwilioVideoRecording(
      organizationKey: string,
      roomSid: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerComposeTwilioVideoRecording(
        organizationKey,
        roomSid,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerCreateActivationCode(
      organizationKey: string,
      groupId: number,
      email: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateActivationCode(
        organizationKey,
        groupId,
        email,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CreateErrorLog} createErrorLog
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerCreateErrorLog(
      createErrorLog: CreateErrorLog,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateErrorLog(
        createErrorLog,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {CreateGroupsInput} createGroupsInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerCreateGroup(
      organizationKey: string,
      createGroupsInput: CreateGroupsInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateGroup(
        organizationKey,
        createGroupsInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CreateInfoLog} createInfoLog
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerCreateInfoLog(
      createInfoLog: CreateInfoLog,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateInfoLog(
        createInfoLog,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {CreateLearnerInput} createLearnerInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerCreateLearner(
      organizationKey: string,
      groupId: number,
      createLearnerInput: CreateLearnerInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateLearner(
        organizationKey,
        groupId,
        createLearnerInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {CreateLearnerInput} createLearnerInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerCreateLearnerFromOrganization(
      organizationKey: string,
      groupId: number,
      createLearnerInput: CreateLearnerInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateLearnerFromOrganization(
        organizationKey,
        groupId,
        createLearnerInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} lessonLogTypeId
     * @param {CreateLessonEntryRecordInput} createLessonEntryRecordInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerCreateLessonEntryRecord(
      organizationKey: string,
      lessonLogTypeId: string,
      createLessonEntryRecordInput: CreateLessonEntryRecordInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateLessonEntryRecord(
        organizationKey,
        lessonLogTypeId,
        createLessonEntryRecordInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {CreateLessonFrameInput} createLessonFrameInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerCreateLessonFrame(
      organizationKey: string,
      groupId: number,
      createLessonFrameInput: CreateLessonFrameInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateLessonFrame(
        organizationKey,
        groupId,
        createLessonFrameInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} groupId
     * @param {string} learnerId
     * @param {CreateLessonLearnerByLearnerIdInput} createLessonLearnerByLearnerIdInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerCreateLessonLearnerByLearnerId(
      organizationKey: string,
      groupId: string,
      learnerId: string,
      createLessonLearnerByLearnerIdInput: CreateLessonLearnerByLearnerIdInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateLessonLearnerByLearnerId(
        organizationKey,
        groupId,
        learnerId,
        createLessonLearnerByLearnerIdInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {CreateNoticeInput} createNoticeInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerCreateNotice(
      organizationKey: string,
      createNoticeInput: CreateNoticeInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateNotice(
        organizationKey,
        createNoticeInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {CreateOrganizationInput} createOrganizationInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerCreateOrganization(
      createOrganizationInput: CreateOrganizationInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateOrganization(
        createOrganizationInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {CreateOrganizationUserInput} createOrganizationUserInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerCreateOrganizationUser(
      organizationKey: string,
      createOrganizationUserInput: CreateOrganizationUserInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateOrganizationUser(
        organizationKey,
        createOrganizationUserInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {CreateTeacherInput} createTeacherInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerCreateTeacher(
      organizationKey: string,
      createTeacherInput: CreateTeacherInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateTeacher(
        organizationKey,
        createTeacherInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerCreateTeacherFromCSV(
      organizationKey: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerCreateTeacherFromCSV(
        organizationKey,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} avtivationCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerDeleteActivationCode(
      organizationKey: string,
      avtivationCode: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteActivationCode(
        organizationKey,
        avtivationCode,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerDeleteGroup(
      organizationKey: string,
      groupId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteGroup(
        organizationKey,
        groupId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerDeleteLearner(
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteLearner(
        organizationKey,
        groupId,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerDeleteLearnerById(
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteLearnerById(
        organizationKey,
        groupId,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {number} lessonId
     * @param {number} lessonBranchNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerDeleteLesson(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      lessonId: number,
      lessonBranchNumber: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteLesson(
        organizationKey,
        groupId,
        lessonFrameId,
        lessonId,
        lessonBranchNumber,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerDeleteLessonFrame(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteLessonFrame(
        organizationKey,
        groupId,
        lessonFrameId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} noticeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerDeleteNotice(
      organizationKey: string,
      groupId: number,
      noticeId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteNotice(
        organizationKey,
        groupId,
        noticeId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerDeleteOrganization(
      organizationKey: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteOrganization(
        organizationKey,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerDeleteTeacher(
      organizationKey: string,
      teacherId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerDeleteTeacher(
        organizationKey,
        teacherId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} groupId
     * @param {string} lessonFrameId
     * @param {string} lessonId
     * @param {RegistPointInput} registPointInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerEntryLesson(
      organizationKey: string,
      groupId: string,
      lessonFrameId: string,
      lessonId: string,
      registPointInput: RegistPointInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerEntryLesson(
        organizationKey,
        groupId,
        lessonFrameId,
        lessonId,
        registPointInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetActivationCode(
      organizationKey: string,
      groupId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetActivationCode(
        organizationKey,
        groupId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {string} nickname
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetCheckNickName(
      organizationKey: string,
      teacherId: string,
      nickname: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetCheckNickName(
        organizationKey,
        teacherId,
        nickname,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetConnectionIdByRoomId(
      organizationKey: string,
      roomId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetConnectionIdByRoomId(
        organizationKey,
        roomId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} groupId
     * @param {string} yyyymm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetCsvContents(
      organizationKey: string,
      groupId: string,
      yyyymm: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetCsvContents(
        organizationKey,
        groupId,
        yyyymm,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetGroupDetail(
      organizationKey: string,
      groupId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetGroupDetail(
        organizationKey,
        groupId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetLearnerPointByLearnerId(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLearnerPointByLearnerId(
        organizationKey,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} limit
     * @param {number} page
     * @param {string} searchStatus
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetLearnersByOrganizationKey(
      organizationKey: string,
      limit: number,
      page: number,
      searchStatus: string,
      searchField: string,
      searchStr: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLearnersByOrganizationKey(
        organizationKey,
        limit,
        page,
        searchStatus,
        searchField,
        searchStr,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetLessonByRoomId(
      organizationKey: string,
      roomId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLessonByRoomId(
        organizationKey,
        roomId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {number} lessonId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetLessonDetail(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      lessonId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLessonDetail(
        organizationKey,
        groupId,
        lessonFrameId,
        lessonId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetLessonFrameDetail(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLessonFrameDetail(
        organizationKey,
        groupId,
        lessonFrameId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetLessonHistoryByTeacherId(
      organizationKey: string,
      teacherId: string,
      limit: number,
      page: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLessonHistoryByTeacherId(
        organizationKey,
        teacherId,
        limit,
        page,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetLessonHistoryBylearnerId(
      organizationKey: string,
      learnerId: string,
      limit: number,
      page: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLessonHistoryBylearnerId(
        organizationKey,
        learnerId,
        limit,
        page,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} startPeriod
     * @param {string} endPeriod
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetLessons(
      organizationKey: string,
      startPeriod: string,
      endPeriod: string,
      searchField: string,
      searchStr: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLessons(
        organizationKey,
        startPeriod,
        endPeriod,
        searchField,
        searchStr,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {string} startPeriod
     * @param {string} endPeriod
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetLessonsByLearnerId(
      organizationKey: string,
      learnerId: string,
      startPeriod: string,
      endPeriod: string,
      searchField: string,
      searchStr: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLessonsByLearnerId(
        organizationKey,
        learnerId,
        startPeriod,
        endPeriod,
        searchField,
        searchStr,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetLessonsByLessonFrameId(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      limit: number,
      page: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLessonsByLessonFrameId(
        organizationKey,
        groupId,
        lessonFrameId,
        limit,
        page,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {string} startPeriod
     * @param {string} endPeriod
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetLessonsByTeacherId(
      organizationKey: string,
      teacherId: string,
      startPeriod: string,
      endPeriod: string,
      searchField: string,
      searchStr: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetLessonsByTeacherId(
        organizationKey,
        teacherId,
        startPeriod,
        endPeriod,
        searchField,
        searchStr,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetMontlyReports(
      organizationKey: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetMontlyReports(
        organizationKey,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetMostRecentLessonByLearnerId(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetMostRecentLessonByLearnerId(
        organizationKey,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetMostRecentLessonByTeacherId(
      organizationKey: string,
      teacherId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetMostRecentLessonByTeacherId(
        organizationKey,
        teacherId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetNotUpdatedNoteLessonsByTeacherId(
      organizationKey: string,
      teacherId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetNotUpdatedNoteLessonsByTeacherId(
        organizationKey,
        teacherId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} noticeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetNotice(
      organizationKey: string,
      noticeId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetNotice(
        organizationKey,
        noticeId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} targetNumber
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetNotices(
      organizationKey: string,
      targetNumber: number,
      limit: number,
      page: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetNotices(
        organizationKey,
        targetNumber,
        limit,
        page,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetOnlyTeacherDataByTeacherId(
      organizationKey: string,
      teacherId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetOnlyTeacherDataByTeacherId(
        organizationKey,
        teacherId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetOrganization(
      organizationKey: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetOrganization(
        organizationKey,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} limit
     * @param {number} page
     * @param {string} searchStatus
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetOrganizationGroups(
      organizationKey: string,
      limit: number,
      page: number,
      searchStatus: string,
      searchField: string,
      searchStr: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetOrganizationGroups(
        organizationKey,
        limit,
        page,
        searchStatus,
        searchField,
        searchStr,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} limit
     * @param {number} page
     * @param {string} searchStatus
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetOrganizationUsers(
      organizationKey: string,
      limit: number,
      page: number,
      searchStatus: string,
      searchField: string,
      searchStr: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetOrganizationUsers(
        organizationKey,
        limit,
        page,
        searchStatus,
        searchField,
        searchStr,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetOrganizations(
      limit: number,
      page: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetOrganizations(
        limit,
        page,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetPushVapidPublicKey(
      organizationKey: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetPushVapidPublicKey(
        organizationKey,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetSubscriptionByLearnerId(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetSubscriptionByLearnerId(
        organizationKey,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetTeacherByTeacherId(
      organizationKey: string,
      teacherId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetTeacherByTeacherId(
        organizationKey,
        teacherId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} limit
     * @param {number} page
     * @param {string} searchStatus
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetTeachersByOrganizationKey(
      organizationKey: string,
      limit: number,
      page: number,
      searchStatus: string,
      searchField: string,
      searchStr: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetTeachersByOrganizationKey(
        organizationKey,
        limit,
        page,
        searchStatus,
        searchField,
        searchStr,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} identity
     * @param {string} userId
     * @param {string} roomName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetTwilioAccessToken(
      organizationKey: string,
      identity: string,
      userId: string,
      roomName: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetTwilioAccessToken(
        organizationKey,
        identity,
        userId,
        roomName,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} roomId
     * @param {CreateRoomChatInput} createRoomChatInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerPutOrganizationRoomChat(
      organizationKey: string,
      roomId: string,
      createRoomChatInput: CreateRoomChatInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerPutOrganizationRoomChat(
        organizationKey,
        roomId,
        createRoomChatInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetleanerByGroup(
      organizationKey: string,
      groupId: number,
      limit: number,
      page: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetleanerByGroup(
        organizationKey,
        groupId,
        limit,
        page,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerGetleanerByLearnerId(
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerGetleanerByLearnerId(
        organizationKey,
        groupId,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {CreatePointInput} createPointInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerInsertLearnerPoints(
      organizationKey: string,
      learnerId: string,
      createPointInput: CreatePointInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerInsertLearnerPoints(
        organizationKey,
        learnerId,
        createPointInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerLessonPermissionOffById(
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerLessonPermissionOffById(
        organizationKey,
        groupId,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerLessonPermissionOnById(
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerLessonPermissionOnById(
        organizationKey,
        groupId,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerResendTeacherInitialPassword(
      organizationKey: string,
      email: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerResendTeacherInitialPassword(
        organizationKey,
        email,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerStartGroup(
      organizationKey: string,
      groupId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerStartGroup(
        organizationKey,
        groupId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerStartLearnerById(
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerStartLearnerById(
        organizationKey,
        groupId,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} noticeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerStartNotice(
      organizationKey: string,
      groupId: number,
      noticeId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerStartNotice(
        organizationKey,
        groupId,
        noticeId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerStartOrganizationBykey(
      organizationKey: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerStartOrganizationBykey(
        organizationKey,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerStartTeacherById(
      organizationKey: string,
      teacherId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerStartTeacherById(
        organizationKey,
        teacherId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerStopGroup(
      organizationKey: string,
      groupId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerStopGroup(
        organizationKey,
        groupId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerStopLearnerById(
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerStopLearnerById(
        organizationKey,
        groupId,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} noticeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerStopNotice(
      organizationKey: string,
      groupId: number,
      noticeId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerStopNotice(
        organizationKey,
        groupId,
        noticeId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerStopOrganizationByKey(
      organizationKey: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerStopOrganizationByKey(
        organizationKey,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerStopTeacherById(
      organizationKey: string,
      teacherId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerStopTeacherById(
        organizationKey,
        teacherId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerUpdateGroup(
      organizationKey: string,
      groupId: number,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateGroup(
        organizationKey,
        groupId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {UpdateLearnerInput} updateLearnerInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerUpdateLearnerById(
      organizationKey: string,
      groupId: number,
      learnerId: string,
      updateLearnerInput: UpdateLearnerInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateLearnerById(
        organizationKey,
        groupId,
        learnerId,
        updateLearnerInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {ConnectionId} connectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerUpdateLearnerConnectionId(
      organizationKey: string,
      teacherId: string,
      connectionId: ConnectionId,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateLearnerConnectionId(
        organizationKey,
        teacherId,
        connectionId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {number} lessonId
     * @param {CreateLessonTeacherInput} createLessonTeacherInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerUpdateLesson(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      lessonId: number,
      createLessonTeacherInput: CreateLessonTeacherInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateLesson(
        organizationKey,
        groupId,
        lessonFrameId,
        lessonId,
        createLessonTeacherInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {CreateLessonFrameInput} createLessonFrameInput
     * @param {any} [lernerMaxNumber]
     * @param {any} [teacherMaxNumber]
     * @param {any} [title]
     * @param {any} [endTime]
     * @param {any} [startTime]
     * @param {any} [lessonDay]
     * @param {any} [deleteFlg]
     * @param {any} [stopFlg]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerUpdateLessonFrame(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      createLessonFrameInput: CreateLessonFrameInput,
      lernerMaxNumber?: any,
      teacherMaxNumber?: any,
      title?: any,
      endTime?: any,
      startTime?: any,
      lessonDay?: any,
      deleteFlg?: any,
      stopFlg?: any,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateLessonFrame(
        organizationKey,
        groupId,
        lessonFrameId,
        createLessonFrameInput,
        lernerMaxNumber,
        teacherMaxNumber,
        title,
        endTime,
        startTime,
        lessonDay,
        deleteFlg,
        stopFlg,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {number} lessonId
     * @param {number} lessonBranchNumber
     * @param {string} learnerId
     * @param {UpdateLessonLearnerFeedbackInput} updateLessonLearnerFeedbackInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerUpdateLessonLearnerFeedback(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      lessonId: number,
      lessonBranchNumber: number,
      learnerId: string,
      updateLessonLearnerFeedbackInput: UpdateLessonLearnerFeedbackInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateLessonLearnerFeedback(
        organizationKey,
        groupId,
        lessonFrameId,
        lessonId,
        lessonBranchNumber,
        learnerId,
        updateLessonLearnerFeedbackInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {number} lessonId
     * @param {UpdateLessonNoteInput} updateLessonNoteInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerUpdateLessonNote(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      lessonId: number,
      updateLessonNoteInput: UpdateLessonNoteInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateLessonNote(
        organizationKey,
        groupId,
        lessonFrameId,
        lessonId,
        updateLessonNoteInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} noticeId
     * @param {UpdateNoticeInput} updateNoticeInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerUpdateNotice(
      organizationKey: string,
      groupId: number,
      noticeId: number,
      updateNoticeInput: UpdateNoticeInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateNotice(
        organizationKey,
        groupId,
        noticeId,
        updateNoticeInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {UpdateOrganizationByKeyInput} updateOrganizationByKeyInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerUpdateOrganization(
      organizationKey: string,
      updateOrganizationByKeyInput: UpdateOrganizationByKeyInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateOrganization(
        organizationKey,
        updateOrganizationByKeyInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} organizationUser
     * @param {UpdateOrganizationUserInput} updateOrganizationUserInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerUpdateOrganizationUser(
      organizationKey: string,
      organizationUser: string,
      updateOrganizationUserInput: UpdateOrganizationUserInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateOrganizationUser(
        organizationKey,
        organizationUser,
        updateOrganizationUserInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {CreateTeacherInput} createTeacherInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerUpdateTeacher(
      organizationKey: string,
      teacherId: string,
      createTeacherInput: CreateTeacherInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateTeacher(
        organizationKey,
        teacherId,
        createTeacherInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {UpdateTeacherBasicInfoInput} updateTeacherBasicInfoInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerUpdateTeacherBasicInfoByTeacherId(
      organizationKey: string,
      teacherId: string,
      updateTeacherBasicInfoInput: UpdateTeacherBasicInfoInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateTeacherBasicInfoByTeacherId(
        organizationKey,
        teacherId,
        updateTeacherBasicInfoInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {UpdateTeacherEmailByTeacherId} updateTeacherEmailByTeacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerUpdateTeacherEmailByTeacherId(
      organizationKey: string,
      teacherId: string,
      updateTeacherEmailByTeacherId: UpdateTeacherEmailByTeacherId,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateTeacherEmailByTeacherId(
        organizationKey,
        teacherId,
        updateTeacherEmailByTeacherId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {UpdateTeacherLanguageSkillInput} updateTeacherLanguageSkillInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerUpdateTeacherLanguageSkillByTeacherId(
      organizationKey: string,
      teacherId: string,
      updateTeacherLanguageSkillInput: UpdateTeacherLanguageSkillInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateTeacherLanguageSkillByTeacherId(
        organizationKey,
        teacherId,
        updateTeacherLanguageSkillInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {UpdateTeacherBasicPartialInfo} updateTeacherBasicPartialInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerUpdateTeacherNickNameByTeacherId(
      organizationKey: string,
      teacherId: string,
      updateTeacherBasicPartialInfo: UpdateTeacherBasicPartialInfo,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateTeacherNickNameByTeacherId(
        organizationKey,
        teacherId,
        updateTeacherBasicPartialInfo,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {UpdateTeacherQualificationInput} updateTeacherQualificationInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerUpdateTeacherQualificationByTeacherId(
      organizationKey: string,
      teacherId: string,
      updateTeacherQualificationInput: UpdateTeacherQualificationInput,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerUpdateTeacherQualificationByTeacherId(
        organizationKey,
        teacherId,
        updateTeacherQualificationInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async organizationsControllerWithdrawLearner(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.organizationsControllerWithdrawLearner(
        organizationKey,
        learnerId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sandboxControllerCognitoSandbox(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sandboxControllerCognitoSandbox(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sandboxControllerMailSandbox(
      options?: any
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sandboxControllerMailSandbox(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * APIApi - factory interface
 * @export
 */
export const APIApiFactory = function(configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = APIApiFp(configuration);
  return {
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {CreateLessonFrameInput} createLessonFrameInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gmoControllerCreateLessonFrame(
      organizationKey: string,
      groupId: number,
      createLessonFrameInput: CreateLessonFrameInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .gmoControllerCreateLessonFrame(organizationKey, groupId, createLessonFrameInput, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {CreateLessonPeriodFramesInput} createLessonPeriodFramesInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gmoControllerCreatePeriodLessonFrames(
      organizationKey: string,
      groupId: number,
      createLessonPeriodFramesInput: CreateLessonPeriodFramesInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .gmoControllerCreatePeriodLessonFrames(organizationKey, groupId, createLessonPeriodFramesInput, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gmoControllerGetPointsHistory(organizationKey: string, learnerId: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .gmoControllerGetPointsHistory(organizationKey, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gmoControllerGetValidPoints(organizationKey: string, learnerId: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .gmoControllerGetValidPoints(organizationKey, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {PaypayResult} paypayResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gmoControllerPostPayPaysales(
      organizationKey: string,
      paypayResult: PaypayResult,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .gmoControllerPostPayPaysales(organizationKey, paypayResult, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {PostTran} postTran
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gmoControllerPostTran(organizationKey: string, postTran: PostTran, options?: any): AxiosPromise<object> {
      return localVarFp
        .gmoControllerPostTran(organizationKey, postTran, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gmoControllerResultNoticeRecive(options?: any): AxiosPromise<object> {
      return localVarFp.gmoControllerResultNoticeRecive(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerAppTest(version: string, options?: any): AxiosPromise<object> {
      return localVarFp.mobileAppControllerAppTest(version, options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} activationCode
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerCheckActivationCode(
      organizationKey: string,
      activationCode: string,
      email: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerCheckActivationCode(organizationKey, activationCode, email, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerCheckActivationCodeWithEmail(
      organizationKey: string,
      email: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerCheckActivationCodeWithEmail(organizationKey, email, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {LearnerQuestionnaire} learnerQuestionnaire
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerCreateLearnerQuestionnaire(
      organizationKey: string,
      learnerId: string,
      learnerQuestionnaire: LearnerQuestionnaire,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerCreateLearnerQuestionnaire(organizationKey, learnerId, learnerQuestionnaire, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerCreateLearnerQuestionnaireSkip(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerCreateLearnerQuestionnaireSkip(organizationKey, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerDeactivateLearnerEndpoints(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerDeactivateLearnerEndpoints(organizationKey, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetIsSubscription(organizationKey: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerGetIsSubscription(organizationKey, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetLearnerActiveEndpointCount(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerGetLearnerActiveEndpointCount(organizationKey, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetLearnerByEmail(organizationKey: string, email: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerGetLearnerByEmail(organizationKey, email, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetLearnerEndpoint(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerGetLearnerEndpoint(organizationKey, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetLearnerQuestionnaire(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerGetLearnerQuestionnaire(organizationKey, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetLearnerQuestionnaireSkip(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerGetLearnerQuestionnaireSkip(organizationKey, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetLernerRole(organizationKey: string, learnerId: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerGetLernerRole(organizationKey, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} noticeId
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetNoticeRead(
      organizationKey: string,
      noticeId: number,
      userName: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerGetNoticeRead(organizationKey, noticeId, userName, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetNoticeUnreadCount(
      organizationKey: string,
      userName: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerGetNoticeUnreadCount(organizationKey, userName, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetPushMsg(organizationKey: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerGetPushMsg(organizationKey, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} pushMsgQId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetPushMsgLog(organizationKey: string, pushMsgQId: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerGetPushMsgLog(organizationKey, pushMsgQId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetPushVapidPublicKey(organizationKey: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerGetPushVapidPublicKey(organizationKey, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetStudyRecords(organizationKey: string, userName: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerGetStudyRecords(organizationKey, userName, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerGetSubscriptions(organizationKey: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerGetSubscriptions(organizationKey, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {StudyResult} studyResult
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerPostStudyRecords(
      organizationKey: string,
      studyResult: StudyResult,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerPostStudyRecords(organizationKey, studyResult, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} noticeId
     * @param {string} userName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerPutNoticeRead(
      organizationKey: string,
      noticeId: number,
      userName: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerPutNoticeRead(organizationKey, noticeId, userName, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {ConnectionId} connectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerUpdateLearnerConnectionId(
      organizationKey: string,
      learnerId: string,
      connectionId: ConnectionId,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerUpdateLearnerConnectionId(organizationKey, learnerId, connectionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerUpdateLearnerEmail(
      organizationKey: string,
      learnerId: string,
      email: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerUpdateLearnerEmail(organizationKey, learnerId, email, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {LearnerEndpoint} learnerEndpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerUpsertLearnerEndpoint(
      organizationKey: string,
      learnerId: string,
      learnerEndpoint: LearnerEndpoint,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerUpsertLearnerEndpoint(organizationKey, learnerId, learnerEndpoint, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {PushMsg} pushMsg
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerUpsertPushMsg(organizationKey: string, pushMsg: PushMsg, options?: any): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerUpsertPushMsg(organizationKey, pushMsg, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {PushMsgLog} pushMsgLog
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerUpsertPushMsgLog(
      organizationKey: string,
      pushMsgLog: PushMsgLog,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerUpsertPushMsgLog(organizationKey, pushMsgLog, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    mobileAppControllerVerifyLearnerEmail(
      organizationKey: string,
      learnerId: string,
      email: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .mobileAppControllerVerifyLearnerEmail(organizationKey, learnerId, email, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {string} teacherId
     * @param {AddLessonByTeacherId} addLessonByTeacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerAddLessonByTeacherId(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      teacherId: string,
      addLessonByTeacherId: AddLessonByTeacherId,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerAddLessonByTeacherId(
          organizationKey,
          groupId,
          lessonFrameId,
          teacherId,
          addLessonByTeacherId,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} roomSid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCompleteRoomStatus(
      organizationKey: string,
      roomSid: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerCompleteRoomStatus(organizationKey, roomSid, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} roomSid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerComposeTwilioVideoRecording(
      organizationKey: string,
      roomSid: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerComposeTwilioVideoRecording(organizationKey, roomSid, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateActivationCode(
      organizationKey: string,
      groupId: number,
      email: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerCreateActivationCode(organizationKey, groupId, email, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CreateErrorLog} createErrorLog
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateErrorLog(createErrorLog: CreateErrorLog, options?: any): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerCreateErrorLog(createErrorLog, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {CreateGroupsInput} createGroupsInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateGroup(
      organizationKey: string,
      createGroupsInput: CreateGroupsInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerCreateGroup(organizationKey, createGroupsInput, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CreateInfoLog} createInfoLog
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateInfoLog(createInfoLog: CreateInfoLog, options?: any): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerCreateInfoLog(createInfoLog, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {CreateLearnerInput} createLearnerInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateLearner(
      organizationKey: string,
      groupId: number,
      createLearnerInput: CreateLearnerInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerCreateLearner(organizationKey, groupId, createLearnerInput, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {CreateLearnerInput} createLearnerInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateLearnerFromOrganization(
      organizationKey: string,
      groupId: number,
      createLearnerInput: CreateLearnerInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerCreateLearnerFromOrganization(organizationKey, groupId, createLearnerInput, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} lessonLogTypeId
     * @param {CreateLessonEntryRecordInput} createLessonEntryRecordInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateLessonEntryRecord(
      organizationKey: string,
      lessonLogTypeId: string,
      createLessonEntryRecordInput: CreateLessonEntryRecordInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerCreateLessonEntryRecord(
          organizationKey,
          lessonLogTypeId,
          createLessonEntryRecordInput,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {CreateLessonFrameInput} createLessonFrameInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateLessonFrame(
      organizationKey: string,
      groupId: number,
      createLessonFrameInput: CreateLessonFrameInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerCreateLessonFrame(organizationKey, groupId, createLessonFrameInput, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} groupId
     * @param {string} learnerId
     * @param {CreateLessonLearnerByLearnerIdInput} createLessonLearnerByLearnerIdInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateLessonLearnerByLearnerId(
      organizationKey: string,
      groupId: string,
      learnerId: string,
      createLessonLearnerByLearnerIdInput: CreateLessonLearnerByLearnerIdInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerCreateLessonLearnerByLearnerId(
          organizationKey,
          groupId,
          learnerId,
          createLessonLearnerByLearnerIdInput,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {CreateNoticeInput} createNoticeInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateNotice(
      organizationKey: string,
      createNoticeInput: CreateNoticeInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerCreateNotice(organizationKey, createNoticeInput, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {CreateOrganizationInput} createOrganizationInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateOrganization(
      createOrganizationInput: CreateOrganizationInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerCreateOrganization(createOrganizationInput, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {CreateOrganizationUserInput} createOrganizationUserInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateOrganizationUser(
      organizationKey: string,
      createOrganizationUserInput: CreateOrganizationUserInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerCreateOrganizationUser(organizationKey, createOrganizationUserInput, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {CreateTeacherInput} createTeacherInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateTeacher(
      organizationKey: string,
      createTeacherInput: CreateTeacherInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerCreateTeacher(organizationKey, createTeacherInput, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerCreateTeacherFromCSV(organizationKey: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerCreateTeacherFromCSV(organizationKey, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} avtivationCode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerDeleteActivationCode(
      organizationKey: string,
      avtivationCode: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerDeleteActivationCode(organizationKey, avtivationCode, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerDeleteGroup(organizationKey: string, groupId: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerDeleteGroup(organizationKey, groupId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerDeleteLearner(
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerDeleteLearner(organizationKey, groupId, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerDeleteLearnerById(
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerDeleteLearnerById(organizationKey, groupId, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {number} lessonId
     * @param {number} lessonBranchNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerDeleteLesson(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      lessonId: number,
      lessonBranchNumber: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerDeleteLesson(
          organizationKey,
          groupId,
          lessonFrameId,
          lessonId,
          lessonBranchNumber,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerDeleteLessonFrame(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerDeleteLessonFrame(organizationKey, groupId, lessonFrameId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} noticeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerDeleteNotice(
      organizationKey: string,
      groupId: number,
      noticeId: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerDeleteNotice(organizationKey, groupId, noticeId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerDeleteOrganization(organizationKey: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerDeleteOrganization(organizationKey, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerDeleteTeacher(
      organizationKey: string,
      teacherId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerDeleteTeacher(organizationKey, teacherId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} groupId
     * @param {string} lessonFrameId
     * @param {string} lessonId
     * @param {RegistPointInput} registPointInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerEntryLesson(
      organizationKey: string,
      groupId: string,
      lessonFrameId: string,
      lessonId: string,
      registPointInput: RegistPointInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerEntryLesson(
          organizationKey,
          groupId,
          lessonFrameId,
          lessonId,
          registPointInput,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetActivationCode(
      organizationKey: string,
      groupId: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetActivationCode(organizationKey, groupId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {string} nickname
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetCheckNickName(
      organizationKey: string,
      teacherId: string,
      nickname: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetCheckNickName(organizationKey, teacherId, nickname, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetConnectionIdByRoomId(
      organizationKey: string,
      roomId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetConnectionIdByRoomId(organizationKey, roomId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} groupId
     * @param {string} yyyymm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetCsvContents(
      organizationKey: string,
      groupId: string,
      yyyymm: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetCsvContents(organizationKey, groupId, yyyymm, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetGroupDetail(
      organizationKey: string,
      groupId: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetGroupDetail(organizationKey, groupId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLearnerPointByLearnerId(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetLearnerPointByLearnerId(organizationKey, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} limit
     * @param {number} page
     * @param {string} searchStatus
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLearnersByOrganizationKey(
      organizationKey: string,
      limit: number,
      page: number,
      searchStatus: string,
      searchField: string,
      searchStr: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetLearnersByOrganizationKey(
          organizationKey,
          limit,
          page,
          searchStatus,
          searchField,
          searchStr,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} roomId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLessonByRoomId(
      organizationKey: string,
      roomId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetLessonByRoomId(organizationKey, roomId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {number} lessonId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLessonDetail(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      lessonId: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetLessonDetail(organizationKey, groupId, lessonFrameId, lessonId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLessonFrameDetail(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetLessonFrameDetail(organizationKey, groupId, lessonFrameId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLessonHistoryByTeacherId(
      organizationKey: string,
      teacherId: string,
      limit: number,
      page: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetLessonHistoryByTeacherId(organizationKey, teacherId, limit, page, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLessonHistoryBylearnerId(
      organizationKey: string,
      learnerId: string,
      limit: number,
      page: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetLessonHistoryBylearnerId(organizationKey, learnerId, limit, page, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} startPeriod
     * @param {string} endPeriod
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLessons(
      organizationKey: string,
      startPeriod: string,
      endPeriod: string,
      searchField: string,
      searchStr: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetLessons(organizationKey, startPeriod, endPeriod, searchField, searchStr, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {string} startPeriod
     * @param {string} endPeriod
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLessonsByLearnerId(
      organizationKey: string,
      learnerId: string,
      startPeriod: string,
      endPeriod: string,
      searchField: string,
      searchStr: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetLessonsByLearnerId(
          organizationKey,
          learnerId,
          startPeriod,
          endPeriod,
          searchField,
          searchStr,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLessonsByLessonFrameId(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      limit: number,
      page: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetLessonsByLessonFrameId(organizationKey, groupId, lessonFrameId, limit, page, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {string} startPeriod
     * @param {string} endPeriod
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetLessonsByTeacherId(
      organizationKey: string,
      teacherId: string,
      startPeriod: string,
      endPeriod: string,
      searchField: string,
      searchStr: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetLessonsByTeacherId(
          organizationKey,
          teacherId,
          startPeriod,
          endPeriod,
          searchField,
          searchStr,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetMontlyReports(organizationKey: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetMontlyReports(organizationKey, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetMostRecentLessonByLearnerId(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetMostRecentLessonByLearnerId(organizationKey, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetMostRecentLessonByTeacherId(
      organizationKey: string,
      teacherId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetMostRecentLessonByTeacherId(organizationKey, teacherId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetNotUpdatedNoteLessonsByTeacherId(
      organizationKey: string,
      teacherId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetNotUpdatedNoteLessonsByTeacherId(organizationKey, teacherId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} noticeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetNotice(organizationKey: string, noticeId: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetNotice(organizationKey, noticeId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} targetNumber
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetNotices(
      organizationKey: string,
      targetNumber: number,
      limit: number,
      page: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetNotices(organizationKey, targetNumber, limit, page, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetOnlyTeacherDataByTeacherId(
      organizationKey: string,
      teacherId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetOnlyTeacherDataByTeacherId(organizationKey, teacherId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetOrganization(organizationKey: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetOrganization(organizationKey, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} limit
     * @param {number} page
     * @param {string} searchStatus
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetOrganizationGroups(
      organizationKey: string,
      limit: number,
      page: number,
      searchStatus: string,
      searchField: string,
      searchStr: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetOrganizationGroups(
          organizationKey,
          limit,
          page,
          searchStatus,
          searchField,
          searchStr,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} limit
     * @param {number} page
     * @param {string} searchStatus
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetOrganizationUsers(
      organizationKey: string,
      limit: number,
      page: number,
      searchStatus: string,
      searchField: string,
      searchStr: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetOrganizationUsers(
          organizationKey,
          limit,
          page,
          searchStatus,
          searchField,
          searchStr,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetOrganizations(limit: number, page: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetOrganizations(limit, page, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetPushVapidPublicKey(organizationKey: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetPushVapidPublicKey(organizationKey, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetSubscriptionByLearnerId(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetSubscriptionByLearnerId(organizationKey, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetTeacherByTeacherId(
      organizationKey: string,
      teacherId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetTeacherByTeacherId(organizationKey, teacherId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} limit
     * @param {number} page
     * @param {string} searchStatus
     * @param {string} searchField
     * @param {string} searchStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetTeachersByOrganizationKey(
      organizationKey: string,
      limit: number,
      page: number,
      searchStatus: string,
      searchField: string,
      searchStr: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetTeachersByOrganizationKey(
          organizationKey,
          limit,
          page,
          searchStatus,
          searchField,
          searchStr,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} identity
     * @param {string} userId
     * @param {string} roomName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetTwilioAccessToken(
      organizationKey: string,
      identity: string,
      userId: string,
      roomName: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetTwilioAccessToken(organizationKey, identity, userId, roomName, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} roomId
     * @param {CreateRoomChatInput} createRoomChatInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APIApi
     */
    organizationsControllerPutOrganizationRoomChat(
      organizationKey: string,
      roomId: string,
      createRoomChatInput: CreateRoomChatInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerPutOrganizationRoomChat(organizationKey, roomId, createRoomChatInput, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} limit
     * @param {number} page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetleanerByGroup(
      organizationKey: string,
      groupId: number,
      limit: number,
      page: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetleanerByGroup(organizationKey, groupId, limit, page, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerGetleanerByLearnerId(
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerGetleanerByLearnerId(organizationKey, groupId, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {CreatePointInput} createPointInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerInsertLearnerPoints(
      organizationKey: string,
      learnerId: string,
      createPointInput: CreatePointInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerInsertLearnerPoints(organizationKey, learnerId, createPointInput, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerLessonPermissionOffById(
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerLessonPermissionOffById(organizationKey, groupId, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerLessonPermissionOnById(
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerLessonPermissionOnById(organizationKey, groupId, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerResendTeacherInitialPassword(
      organizationKey: string,
      email: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerResendTeacherInitialPassword(organizationKey, email, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStartGroup(organizationKey: string, groupId: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerStartGroup(organizationKey, groupId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStartLearnerById(
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerStartLearnerById(organizationKey, groupId, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} noticeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStartNotice(
      organizationKey: string,
      groupId: number,
      noticeId: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerStartNotice(organizationKey, groupId, noticeId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStartOrganizationBykey(organizationKey: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerStartOrganizationBykey(organizationKey, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStartTeacherById(
      organizationKey: string,
      teacherId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerStartTeacherById(organizationKey, teacherId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStopGroup(organizationKey: string, groupId: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerStopGroup(organizationKey, groupId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStopLearnerById(
      organizationKey: string,
      groupId: number,
      learnerId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerStopLearnerById(organizationKey, groupId, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} noticeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStopNotice(
      organizationKey: string,
      groupId: number,
      noticeId: number,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerStopNotice(organizationKey, groupId, noticeId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStopOrganizationByKey(organizationKey: string, options?: any): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerStopOrganizationByKey(organizationKey, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerStopTeacherById(
      organizationKey: string,
      teacherId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerStopTeacherById(organizationKey, teacherId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateGroup(organizationKey: string, groupId: number, options?: any): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerUpdateGroup(organizationKey, groupId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {string} learnerId
     * @param {UpdateLearnerInput} updateLearnerInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateLearnerById(
      organizationKey: string,
      groupId: number,
      learnerId: string,
      updateLearnerInput: UpdateLearnerInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerUpdateLearnerById(organizationKey, groupId, learnerId, updateLearnerInput, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {ConnectionId} connectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateLearnerConnectionId(
      organizationKey: string,
      teacherId: string,
      connectionId: ConnectionId,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerUpdateLearnerConnectionId(organizationKey, teacherId, connectionId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {number} lessonId
     * @param {CreateLessonTeacherInput} createLessonTeacherInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateLesson(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      lessonId: number,
      createLessonTeacherInput: CreateLessonTeacherInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerUpdateLesson(
          organizationKey,
          groupId,
          lessonFrameId,
          lessonId,
          createLessonTeacherInput,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {CreateLessonFrameInput} createLessonFrameInput
     * @param {any} [lernerMaxNumber]
     * @param {any} [teacherMaxNumber]
     * @param {any} [title]
     * @param {any} [endTime]
     * @param {any} [startTime]
     * @param {any} [lessonDay]
     * @param {any} [deleteFlg]
     * @param {any} [stopFlg]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateLessonFrame(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      createLessonFrameInput: CreateLessonFrameInput,
      lernerMaxNumber?: any,
      teacherMaxNumber?: any,
      title?: any,
      endTime?: any,
      startTime?: any,
      lessonDay?: any,
      deleteFlg?: any,
      stopFlg?: any,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerUpdateLessonFrame(
          organizationKey,
          groupId,
          lessonFrameId,
          createLessonFrameInput,
          lernerMaxNumber,
          teacherMaxNumber,
          title,
          endTime,
          startTime,
          lessonDay,
          deleteFlg,
          stopFlg,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {number} lessonId
     * @param {number} lessonBranchNumber
     * @param {string} learnerId
     * @param {UpdateLessonLearnerFeedbackInput} updateLessonLearnerFeedbackInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateLessonLearnerFeedback(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      lessonId: number,
      lessonBranchNumber: number,
      learnerId: string,
      updateLessonLearnerFeedbackInput: UpdateLessonLearnerFeedbackInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerUpdateLessonLearnerFeedback(
          organizationKey,
          groupId,
          lessonFrameId,
          lessonId,
          lessonBranchNumber,
          learnerId,
          updateLessonLearnerFeedbackInput,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} lessonFrameId
     * @param {number} lessonId
     * @param {UpdateLessonNoteInput} updateLessonNoteInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateLessonNote(
      organizationKey: string,
      groupId: number,
      lessonFrameId: number,
      lessonId: number,
      updateLessonNoteInput: UpdateLessonNoteInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerUpdateLessonNote(
          organizationKey,
          groupId,
          lessonFrameId,
          lessonId,
          updateLessonNoteInput,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {number} groupId
     * @param {number} noticeId
     * @param {UpdateNoticeInput} updateNoticeInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateNotice(
      organizationKey: string,
      groupId: number,
      noticeId: number,
      updateNoticeInput: UpdateNoticeInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerUpdateNotice(organizationKey, groupId, noticeId, updateNoticeInput, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {UpdateOrganizationByKeyInput} updateOrganizationByKeyInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateOrganization(
      organizationKey: string,
      updateOrganizationByKeyInput: UpdateOrganizationByKeyInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerUpdateOrganization(organizationKey, updateOrganizationByKeyInput, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} organizationUser
     * @param {UpdateOrganizationUserInput} updateOrganizationUserInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateOrganizationUser(
      organizationKey: string,
      organizationUser: string,
      updateOrganizationUserInput: UpdateOrganizationUserInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerUpdateOrganizationUser(
          organizationKey,
          organizationUser,
          updateOrganizationUserInput,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {CreateTeacherInput} createTeacherInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateTeacher(
      organizationKey: string,
      teacherId: string,
      createTeacherInput: CreateTeacherInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerUpdateTeacher(organizationKey, teacherId, createTeacherInput, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {UpdateTeacherBasicInfoInput} updateTeacherBasicInfoInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateTeacherBasicInfoByTeacherId(
      organizationKey: string,
      teacherId: string,
      updateTeacherBasicInfoInput: UpdateTeacherBasicInfoInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerUpdateTeacherBasicInfoByTeacherId(
          organizationKey,
          teacherId,
          updateTeacherBasicInfoInput,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {UpdateTeacherEmailByTeacherId} updateTeacherEmailByTeacherId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateTeacherEmailByTeacherId(
      organizationKey: string,
      teacherId: string,
      updateTeacherEmailByTeacherId: UpdateTeacherEmailByTeacherId,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerUpdateTeacherEmailByTeacherId(
          organizationKey,
          teacherId,
          updateTeacherEmailByTeacherId,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {UpdateTeacherLanguageSkillInput} updateTeacherLanguageSkillInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateTeacherLanguageSkillByTeacherId(
      organizationKey: string,
      teacherId: string,
      updateTeacherLanguageSkillInput: UpdateTeacherLanguageSkillInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerUpdateTeacherLanguageSkillByTeacherId(
          organizationKey,
          teacherId,
          updateTeacherLanguageSkillInput,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {UpdateTeacherBasicPartialInfo} updateTeacherBasicPartialInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateTeacherNickNameByTeacherId(
      organizationKey: string,
      teacherId: string,
      updateTeacherBasicPartialInfo: UpdateTeacherBasicPartialInfo,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerUpdateTeacherNickNameByTeacherId(
          organizationKey,
          teacherId,
          updateTeacherBasicPartialInfo,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} teacherId
     * @param {UpdateTeacherQualificationInput} updateTeacherQualificationInput
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerUpdateTeacherQualificationByTeacherId(
      organizationKey: string,
      teacherId: string,
      updateTeacherQualificationInput: UpdateTeacherQualificationInput,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerUpdateTeacherQualificationByTeacherId(
          organizationKey,
          teacherId,
          updateTeacherQualificationInput,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {string} organizationKey
     * @param {string} learnerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    organizationsControllerWithdrawLearner(
      organizationKey: string,
      learnerId: string,
      options?: any
    ): AxiosPromise<object> {
      return localVarFp
        .organizationsControllerWithdrawLearner(organizationKey, learnerId, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sandboxControllerCognitoSandbox(options?: any): AxiosPromise<string> {
      return localVarFp.sandboxControllerCognitoSandbox(options).then(request => request(axios, basePath));
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sandboxControllerMailSandbox(options?: any): AxiosPromise<string> {
      return localVarFp.sandboxControllerMailSandbox(options).then(request => request(axios, basePath));
    },
  };
};

/**
 * APIApi - object-oriented interface
 * @export
 * @class APIApi
 * @extends {BaseAPI}
 */
export class APIApi extends BaseAPI {
  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {CreateLessonFrameInput} createLessonFrameInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public gmoControllerCreateLessonFrame(
    organizationKey: string,
    groupId: number,
    createLessonFrameInput: CreateLessonFrameInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .gmoControllerCreateLessonFrame(organizationKey, groupId, createLessonFrameInput, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {CreateLessonPeriodFramesInput} createLessonPeriodFramesInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public gmoControllerCreatePeriodLessonFrames(
    organizationKey: string,
    groupId: number,
    createLessonPeriodFramesInput: CreateLessonPeriodFramesInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .gmoControllerCreatePeriodLessonFrames(organizationKey, groupId, createLessonPeriodFramesInput, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public gmoControllerGetPointsHistory(organizationKey: string, learnerId: string, options?: any) {
    return APIApiFp(this.configuration)
      .gmoControllerGetPointsHistory(organizationKey, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public gmoControllerGetValidPoints(organizationKey: string, learnerId: string, options?: any) {
    return APIApiFp(this.configuration)
      .gmoControllerGetValidPoints(organizationKey, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {PaypayResult} paypayResult
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public gmoControllerPostPayPaysales(organizationKey: string, paypayResult: PaypayResult, options?: any) {
    return APIApiFp(this.configuration)
      .gmoControllerPostPayPaysales(organizationKey, paypayResult, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {PostTran} postTran
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public gmoControllerPostTran(organizationKey: string, postTran: PostTran, options?: any) {
    return APIApiFp(this.configuration)
      .gmoControllerPostTran(organizationKey, postTran, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public gmoControllerResultNoticeRecive(options?: any) {
    return APIApiFp(this.configuration)
      .gmoControllerResultNoticeRecive(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerAppTest(version: string, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerAppTest(version, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} activationCode
   * @param {string} email
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerCheckActivationCode(
    organizationKey: string,
    activationCode: string,
    email: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .mobileAppControllerCheckActivationCode(organizationKey, activationCode, email, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} email
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerCheckActivationCodeWithEmail(organizationKey: string, email: string, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerCheckActivationCodeWithEmail(organizationKey, email, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {LearnerQuestionnaire} learnerQuestionnaire
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerCreateLearnerQuestionnaire(
    organizationKey: string,
    learnerId: string,
    learnerQuestionnaire: LearnerQuestionnaire,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .mobileAppControllerCreateLearnerQuestionnaire(organizationKey, learnerId, learnerQuestionnaire, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerCreateLearnerQuestionnaireSkip(organizationKey: string, learnerId: string, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerCreateLearnerQuestionnaireSkip(organizationKey, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerDeactivateLearnerEndpoints(organizationKey: string, learnerId: string, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerDeactivateLearnerEndpoints(organizationKey, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerGetIsSubscription(organizationKey: string, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerGetIsSubscription(organizationKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerGetLearnerActiveEndpointCount(organizationKey: string, learnerId: string, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerGetLearnerActiveEndpointCount(organizationKey, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} email
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerGetLearnerByEmail(organizationKey: string, email: string, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerGetLearnerByEmail(organizationKey, email, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerGetLearnerEndpoint(organizationKey: string, learnerId: string, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerGetLearnerEndpoint(organizationKey, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerGetLearnerQuestionnaire(organizationKey: string, learnerId: string, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerGetLearnerQuestionnaire(organizationKey, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerGetLearnerQuestionnaireSkip(organizationKey: string, learnerId: string, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerGetLearnerQuestionnaireSkip(organizationKey, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerGetLernerRole(organizationKey: string, learnerId: string, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerGetLernerRole(organizationKey, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} noticeId
   * @param {string} userName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerGetNoticeRead(organizationKey: string, noticeId: number, userName: string, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerGetNoticeRead(organizationKey, noticeId, userName, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} userName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerGetNoticeUnreadCount(organizationKey: string, userName: string, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerGetNoticeUnreadCount(organizationKey, userName, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerGetPushMsg(organizationKey: string, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerGetPushMsg(organizationKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} pushMsgQId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerGetPushMsgLog(organizationKey: string, pushMsgQId: number, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerGetPushMsgLog(organizationKey, pushMsgQId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerGetPushVapidPublicKey(organizationKey: string, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerGetPushVapidPublicKey(organizationKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} userName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerGetStudyRecords(organizationKey: string, userName: string, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerGetStudyRecords(organizationKey, userName, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerGetSubscriptions(organizationKey: string, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerGetSubscriptions(organizationKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {StudyResult} studyResult
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerPostStudyRecords(organizationKey: string, studyResult: StudyResult, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerPostStudyRecords(organizationKey, studyResult, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} noticeId
   * @param {string} userName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerPutNoticeRead(organizationKey: string, noticeId: number, userName: string, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerPutNoticeRead(organizationKey, noticeId, userName, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {ConnectionId} connectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerUpdateLearnerConnectionId(
    organizationKey: string,
    learnerId: string,
    connectionId: ConnectionId,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .mobileAppControllerUpdateLearnerConnectionId(organizationKey, learnerId, connectionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {string} email
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerUpdateLearnerEmail(
    organizationKey: string,
    learnerId: string,
    email: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .mobileAppControllerUpdateLearnerEmail(organizationKey, learnerId, email, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {LearnerEndpoint} learnerEndpoint
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerUpsertLearnerEndpoint(
    organizationKey: string,
    learnerId: string,
    learnerEndpoint: LearnerEndpoint,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .mobileAppControllerUpsertLearnerEndpoint(organizationKey, learnerId, learnerEndpoint, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {PushMsg} pushMsg
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerUpsertPushMsg(organizationKey: string, pushMsg: PushMsg, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerUpsertPushMsg(organizationKey, pushMsg, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {PushMsgLog} pushMsgLog
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerUpsertPushMsgLog(organizationKey: string, pushMsgLog: PushMsgLog, options?: any) {
    return APIApiFp(this.configuration)
      .mobileAppControllerUpsertPushMsgLog(organizationKey, pushMsgLog, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {string} email
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public mobileAppControllerVerifyLearnerEmail(
    organizationKey: string,
    learnerId: string,
    email: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .mobileAppControllerVerifyLearnerEmail(organizationKey, learnerId, email, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {number} lessonFrameId
   * @param {string} teacherId
   * @param {AddLessonByTeacherId} addLessonByTeacherId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerAddLessonByTeacherId(
    organizationKey: string,
    groupId: number,
    lessonFrameId: number,
    teacherId: string,
    addLessonByTeacherId: AddLessonByTeacherId,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerAddLessonByTeacherId(
        organizationKey,
        groupId,
        lessonFrameId,
        teacherId,
        addLessonByTeacherId,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} roomSid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerCompleteRoomStatus(organizationKey: string, roomSid: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerCompleteRoomStatus(organizationKey, roomSid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} roomSid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerComposeTwilioVideoRecording(organizationKey: string, roomSid: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerComposeTwilioVideoRecording(organizationKey, roomSid, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {string} email
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerCreateActivationCode(
    organizationKey: string,
    groupId: number,
    email: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerCreateActivationCode(organizationKey, groupId, email, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateErrorLog} createErrorLog
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerCreateErrorLog(createErrorLog: CreateErrorLog, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerCreateErrorLog(createErrorLog, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {CreateGroupsInput} createGroupsInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerCreateGroup(
    organizationKey: string,
    createGroupsInput: CreateGroupsInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerCreateGroup(organizationKey, createGroupsInput, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateInfoLog} createInfoLog
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerCreateInfoLog(createInfoLog: CreateInfoLog, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerCreateInfoLog(createInfoLog, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {CreateLearnerInput} createLearnerInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerCreateLearner(
    organizationKey: string,
    groupId: number,
    createLearnerInput: CreateLearnerInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerCreateLearner(organizationKey, groupId, createLearnerInput, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {CreateLearnerInput} createLearnerInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerCreateLearnerFromOrganization(
    organizationKey: string,
    groupId: number,
    createLearnerInput: CreateLearnerInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerCreateLearnerFromOrganization(organizationKey, groupId, createLearnerInput, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} lessonLogTypeId
   * @param {CreateLessonEntryRecordInput} createLessonEntryRecordInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerCreateLessonEntryRecord(
    organizationKey: string,
    lessonLogTypeId: string,
    createLessonEntryRecordInput: CreateLessonEntryRecordInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerCreateLessonEntryRecord(
        organizationKey,
        lessonLogTypeId,
        createLessonEntryRecordInput,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {CreateLessonFrameInput} createLessonFrameInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerCreateLessonFrame(
    organizationKey: string,
    groupId: number,
    createLessonFrameInput: CreateLessonFrameInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerCreateLessonFrame(organizationKey, groupId, createLessonFrameInput, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} groupId
   * @param {string} learnerId
   * @param {CreateLessonLearnerByLearnerIdInput} createLessonLearnerByLearnerIdInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerCreateLessonLearnerByLearnerId(
    organizationKey: string,
    groupId: string,
    learnerId: string,
    createLessonLearnerByLearnerIdInput: CreateLessonLearnerByLearnerIdInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerCreateLessonLearnerByLearnerId(
        organizationKey,
        groupId,
        learnerId,
        createLessonLearnerByLearnerIdInput,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {CreateNoticeInput} createNoticeInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerCreateNotice(
    organizationKey: string,
    createNoticeInput: CreateNoticeInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerCreateNotice(organizationKey, createNoticeInput, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {CreateOrganizationInput} createOrganizationInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerCreateOrganization(createOrganizationInput: CreateOrganizationInput, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerCreateOrganization(createOrganizationInput, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {CreateOrganizationUserInput} createOrganizationUserInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerCreateOrganizationUser(
    organizationKey: string,
    createOrganizationUserInput: CreateOrganizationUserInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerCreateOrganizationUser(organizationKey, createOrganizationUserInput, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {CreateTeacherInput} createTeacherInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerCreateTeacher(
    organizationKey: string,
    createTeacherInput: CreateTeacherInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerCreateTeacher(organizationKey, createTeacherInput, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerCreateTeacherFromCSV(organizationKey: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerCreateTeacherFromCSV(organizationKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} avtivationCode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerDeleteActivationCode(organizationKey: string, avtivationCode: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerDeleteActivationCode(organizationKey, avtivationCode, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerDeleteGroup(organizationKey: string, groupId: number, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerDeleteGroup(organizationKey, groupId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerDeleteLearner(
    organizationKey: string,
    groupId: number,
    learnerId: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerDeleteLearner(organizationKey, groupId, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerDeleteLearnerById(
    organizationKey: string,
    groupId: number,
    learnerId: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerDeleteLearnerById(organizationKey, groupId, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {number} lessonFrameId
   * @param {number} lessonId
   * @param {number} lessonBranchNumber
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerDeleteLesson(
    organizationKey: string,
    groupId: number,
    lessonFrameId: number,
    lessonId: number,
    lessonBranchNumber: number,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerDeleteLesson(
        organizationKey,
        groupId,
        lessonFrameId,
        lessonId,
        lessonBranchNumber,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {number} lessonFrameId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerDeleteLessonFrame(
    organizationKey: string,
    groupId: number,
    lessonFrameId: number,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerDeleteLessonFrame(organizationKey, groupId, lessonFrameId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {number} noticeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerDeleteNotice(
    organizationKey: string,
    groupId: number,
    noticeId: number,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerDeleteNotice(organizationKey, groupId, noticeId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerDeleteOrganization(organizationKey: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerDeleteOrganization(organizationKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} teacherId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerDeleteTeacher(organizationKey: string, teacherId: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerDeleteTeacher(organizationKey, teacherId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} groupId
   * @param {string} lessonFrameId
   * @param {string} lessonId
   * @param {RegistPointInput} registPointInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerEntryLesson(
    organizationKey: string,
    groupId: string,
    lessonFrameId: string,
    lessonId: string,
    registPointInput: RegistPointInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerEntryLesson(organizationKey, groupId, lessonFrameId, lessonId, registPointInput, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetActivationCode(organizationKey: string, groupId: number, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetActivationCode(organizationKey, groupId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} teacherId
   * @param {string} nickname
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetCheckNickName(
    organizationKey: string,
    teacherId: string,
    nickname: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetCheckNickName(organizationKey, teacherId, nickname, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} roomId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetConnectionIdByRoomId(organizationKey: string, roomId: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetConnectionIdByRoomId(organizationKey, roomId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} groupId
   * @param {string} yyyymm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetCsvContents(
    organizationKey: string,
    groupId: string,
    yyyymm: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetCsvContents(organizationKey, groupId, yyyymm, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetGroupDetail(organizationKey: string, groupId: number, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetGroupDetail(organizationKey, groupId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetLearnerPointByLearnerId(organizationKey: string, learnerId: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetLearnerPointByLearnerId(organizationKey, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} limit
   * @param {number} page
   * @param {string} searchStatus
   * @param {string} searchField
   * @param {string} searchStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetLearnersByOrganizationKey(
    organizationKey: string,
    limit: number,
    page: number,
    searchStatus: string,
    searchField: string,
    searchStr: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetLearnersByOrganizationKey(
        organizationKey,
        limit,
        page,
        searchStatus,
        searchField,
        searchStr,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} roomId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetLessonByRoomId(organizationKey: string, roomId: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetLessonByRoomId(organizationKey, roomId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {number} lessonFrameId
   * @param {number} lessonId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetLessonDetail(
    organizationKey: string,
    groupId: number,
    lessonFrameId: number,
    lessonId: number,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetLessonDetail(organizationKey, groupId, lessonFrameId, lessonId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {number} lessonFrameId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetLessonFrameDetail(
    organizationKey: string,
    groupId: number,
    lessonFrameId: number,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetLessonFrameDetail(organizationKey, groupId, lessonFrameId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} teacherId
   * @param {number} limit
   * @param {number} page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetLessonHistoryByTeacherId(
    organizationKey: string,
    teacherId: string,
    limit: number,
    page: number,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetLessonHistoryByTeacherId(organizationKey, teacherId, limit, page, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {number} limit
   * @param {number} page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetLessonHistoryBylearnerId(
    organizationKey: string,
    learnerId: string,
    limit: number,
    page: number,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetLessonHistoryBylearnerId(organizationKey, learnerId, limit, page, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} startPeriod
   * @param {string} endPeriod
   * @param {string} searchField
   * @param {string} searchStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetLessons(
    organizationKey: string,
    startPeriod: string,
    endPeriod: string,
    searchField: string,
    searchStr: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetLessons(organizationKey, startPeriod, endPeriod, searchField, searchStr, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {string} startPeriod
   * @param {string} endPeriod
   * @param {string} searchField
   * @param {string} searchStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetLessonsByLearnerId(
    organizationKey: string,
    learnerId: string,
    startPeriod: string,
    endPeriod: string,
    searchField: string,
    searchStr: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetLessonsByLearnerId(
        organizationKey,
        learnerId,
        startPeriod,
        endPeriod,
        searchField,
        searchStr,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {number} lessonFrameId
   * @param {number} limit
   * @param {number} page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetLessonsByLessonFrameId(
    organizationKey: string,
    groupId: number,
    lessonFrameId: number,
    limit: number,
    page: number,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetLessonsByLessonFrameId(organizationKey, groupId, lessonFrameId, limit, page, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} teacherId
   * @param {string} startPeriod
   * @param {string} endPeriod
   * @param {string} searchField
   * @param {string} searchStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetLessonsByTeacherId(
    organizationKey: string,
    teacherId: string,
    startPeriod: string,
    endPeriod: string,
    searchField: string,
    searchStr: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetLessonsByTeacherId(
        organizationKey,
        teacherId,
        startPeriod,
        endPeriod,
        searchField,
        searchStr,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetMontlyReports(organizationKey: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetMontlyReports(organizationKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetMostRecentLessonByLearnerId(
    organizationKey: string,
    learnerId: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetMostRecentLessonByLearnerId(organizationKey, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} teacherId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetMostRecentLessonByTeacherId(
    organizationKey: string,
    teacherId: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetMostRecentLessonByTeacherId(organizationKey, teacherId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} teacherId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetNotUpdatedNoteLessonsByTeacherId(
    organizationKey: string,
    teacherId: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetNotUpdatedNoteLessonsByTeacherId(organizationKey, teacherId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} noticeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetNotice(organizationKey: string, noticeId: number, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetNotice(organizationKey, noticeId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} targetNumber
   * @param {number} limit
   * @param {number} page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetNotices(
    organizationKey: string,
    targetNumber: number,
    limit: number,
    page: number,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetNotices(organizationKey, targetNumber, limit, page, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} teacherId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetOnlyTeacherDataByTeacherId(
    organizationKey: string,
    teacherId: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetOnlyTeacherDataByTeacherId(organizationKey, teacherId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetOrganization(organizationKey: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetOrganization(organizationKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} limit
   * @param {number} page
   * @param {string} searchStatus
   * @param {string} searchField
   * @param {string} searchStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetOrganizationGroups(
    organizationKey: string,
    limit: number,
    page: number,
    searchStatus: string,
    searchField: string,
    searchStr: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetOrganizationGroups(
        organizationKey,
        limit,
        page,
        searchStatus,
        searchField,
        searchStr,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} limit
   * @param {number} page
   * @param {string} searchStatus
   * @param {string} searchField
   * @param {string} searchStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetOrganizationUsers(
    organizationKey: string,
    limit: number,
    page: number,
    searchStatus: string,
    searchField: string,
    searchStr: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetOrganizationUsers(
        organizationKey,
        limit,
        page,
        searchStatus,
        searchField,
        searchStr,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {number} limit
   * @param {number} page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetOrganizations(limit: number, page: number, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetOrganizations(limit, page, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetPushVapidPublicKey(organizationKey: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetPushVapidPublicKey(organizationKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetSubscriptionByLearnerId(organizationKey: string, learnerId: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetSubscriptionByLearnerId(organizationKey, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} teacherId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetTeacherByTeacherId(organizationKey: string, teacherId: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetTeacherByTeacherId(organizationKey, teacherId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} limit
   * @param {number} page
   * @param {string} searchStatus
   * @param {string} searchField
   * @param {string} searchStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetTeachersByOrganizationKey(
    organizationKey: string,
    limit: number,
    page: number,
    searchStatus: string,
    searchField: string,
    searchStr: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetTeachersByOrganizationKey(
        organizationKey,
        limit,
        page,
        searchStatus,
        searchField,
        searchStr,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} identity
   * @param {string} userId
   * @param {string} roomName
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetTwilioAccessToken(
    organizationKey: string,
    identity: string,
    userId: string,
    roomName: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetTwilioAccessToken(organizationKey, identity, userId, roomName, options)
      .then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {string} organizationKey
   * @param {string} roomId
   * @param {CreateRoomChatInput} createRoomChatInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerPutOrganizationRoomChat(
    organizationKey: string,
    roomId: string,
    createRoomChatInput: CreateRoomChatInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerPutOrganizationRoomChat(organizationKey, roomId, createRoomChatInput, options)
      .then(request => request(this.axios, this.basePath));
  }
  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {number} limit
   * @param {number} page
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetleanerByGroup(
    organizationKey: string,
    groupId: number,
    limit: number,
    page: number,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetleanerByGroup(organizationKey, groupId, limit, page, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerGetleanerByLearnerId(
    organizationKey: string,
    groupId: number,
    learnerId: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerGetleanerByLearnerId(organizationKey, groupId, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {CreatePointInput} createPointInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerInsertLearnerPoints(
    organizationKey: string,
    learnerId: string,
    createPointInput: CreatePointInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerInsertLearnerPoints(organizationKey, learnerId, createPointInput, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerLessonPermissionOffById(
    organizationKey: string,
    groupId: number,
    learnerId: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerLessonPermissionOffById(organizationKey, groupId, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerLessonPermissionOnById(
    organizationKey: string,
    groupId: number,
    learnerId: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerLessonPermissionOnById(organizationKey, groupId, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} email
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerResendTeacherInitialPassword(organizationKey: string, email: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerResendTeacherInitialPassword(organizationKey, email, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerStartGroup(organizationKey: string, groupId: number, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerStartGroup(organizationKey, groupId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerStartLearnerById(
    organizationKey: string,
    groupId: number,
    learnerId: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerStartLearnerById(organizationKey, groupId, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {number} noticeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerStartNotice(organizationKey: string, groupId: number, noticeId: number, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerStartNotice(organizationKey, groupId, noticeId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerStartOrganizationBykey(organizationKey: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerStartOrganizationBykey(organizationKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} teacherId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerStartTeacherById(organizationKey: string, teacherId: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerStartTeacherById(organizationKey, teacherId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerStopGroup(organizationKey: string, groupId: number, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerStopGroup(organizationKey, groupId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerStopLearnerById(
    organizationKey: string,
    groupId: number,
    learnerId: string,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerStopLearnerById(organizationKey, groupId, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {number} noticeId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerStopNotice(organizationKey: string, groupId: number, noticeId: number, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerStopNotice(organizationKey, groupId, noticeId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerStopOrganizationByKey(organizationKey: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerStopOrganizationByKey(organizationKey, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} teacherId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerStopTeacherById(organizationKey: string, teacherId: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerStopTeacherById(organizationKey, teacherId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerUpdateGroup(organizationKey: string, groupId: number, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerUpdateGroup(organizationKey, groupId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {string} learnerId
   * @param {UpdateLearnerInput} updateLearnerInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerUpdateLearnerById(
    organizationKey: string,
    groupId: number,
    learnerId: string,
    updateLearnerInput: UpdateLearnerInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerUpdateLearnerById(organizationKey, groupId, learnerId, updateLearnerInput, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} teacherId
   * @param {ConnectionId} connectionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerUpdateLearnerConnectionId(
    organizationKey: string,
    teacherId: string,
    connectionId: ConnectionId,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerUpdateLearnerConnectionId(organizationKey, teacherId, connectionId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {number} lessonFrameId
   * @param {number} lessonId
   * @param {CreateLessonTeacherInput} createLessonTeacherInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerUpdateLesson(
    organizationKey: string,
    groupId: number,
    lessonFrameId: number,
    lessonId: number,
    createLessonTeacherInput: CreateLessonTeacherInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerUpdateLesson(
        organizationKey,
        groupId,
        lessonFrameId,
        lessonId,
        createLessonTeacherInput,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {number} lessonFrameId
   * @param {CreateLessonFrameInput} createLessonFrameInput
   * @param {any} [lernerMaxNumber]
   * @param {any} [teacherMaxNumber]
   * @param {any} [title]
   * @param {any} [endTime]
   * @param {any} [startTime]
   * @param {any} [lessonDay]
   * @param {any} [deleteFlg]
   * @param {any} [stopFlg]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerUpdateLessonFrame(
    organizationKey: string,
    groupId: number,
    lessonFrameId: number,
    createLessonFrameInput: CreateLessonFrameInput,
    lernerMaxNumber?: any,
    teacherMaxNumber?: any,
    title?: any,
    endTime?: any,
    startTime?: any,
    lessonDay?: any,
    deleteFlg?: any,
    stopFlg?: any,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerUpdateLessonFrame(
        organizationKey,
        groupId,
        lessonFrameId,
        createLessonFrameInput,
        lernerMaxNumber,
        teacherMaxNumber,
        title,
        endTime,
        startTime,
        lessonDay,
        deleteFlg,
        stopFlg,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {number} lessonFrameId
   * @param {number} lessonId
   * @param {number} lessonBranchNumber
   * @param {string} learnerId
   * @param {UpdateLessonLearnerFeedbackInput} updateLessonLearnerFeedbackInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerUpdateLessonLearnerFeedback(
    organizationKey: string,
    groupId: number,
    lessonFrameId: number,
    lessonId: number,
    lessonBranchNumber: number,
    learnerId: string,
    updateLessonLearnerFeedbackInput: UpdateLessonLearnerFeedbackInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerUpdateLessonLearnerFeedback(
        organizationKey,
        groupId,
        lessonFrameId,
        lessonId,
        lessonBranchNumber,
        learnerId,
        updateLessonLearnerFeedbackInput,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {number} lessonFrameId
   * @param {number} lessonId
   * @param {UpdateLessonNoteInput} updateLessonNoteInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerUpdateLessonNote(
    organizationKey: string,
    groupId: number,
    lessonFrameId: number,
    lessonId: number,
    updateLessonNoteInput: UpdateLessonNoteInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerUpdateLessonNote(
        organizationKey,
        groupId,
        lessonFrameId,
        lessonId,
        updateLessonNoteInput,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {number} groupId
   * @param {number} noticeId
   * @param {UpdateNoticeInput} updateNoticeInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerUpdateNotice(
    organizationKey: string,
    groupId: number,
    noticeId: number,
    updateNoticeInput: UpdateNoticeInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerUpdateNotice(organizationKey, groupId, noticeId, updateNoticeInput, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {UpdateOrganizationByKeyInput} updateOrganizationByKeyInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerUpdateOrganization(
    organizationKey: string,
    updateOrganizationByKeyInput: UpdateOrganizationByKeyInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerUpdateOrganization(organizationKey, updateOrganizationByKeyInput, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} organizationUser
   * @param {UpdateOrganizationUserInput} updateOrganizationUserInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerUpdateOrganizationUser(
    organizationKey: string,
    organizationUser: string,
    updateOrganizationUserInput: UpdateOrganizationUserInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerUpdateOrganizationUser(
        organizationKey,
        organizationUser,
        updateOrganizationUserInput,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} teacherId
   * @param {CreateTeacherInput} createTeacherInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerUpdateTeacher(
    organizationKey: string,
    teacherId: string,
    createTeacherInput: CreateTeacherInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerUpdateTeacher(organizationKey, teacherId, createTeacherInput, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} teacherId
   * @param {UpdateTeacherBasicInfoInput} updateTeacherBasicInfoInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerUpdateTeacherBasicInfoByTeacherId(
    organizationKey: string,
    teacherId: string,
    updateTeacherBasicInfoInput: UpdateTeacherBasicInfoInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerUpdateTeacherBasicInfoByTeacherId(
        organizationKey,
        teacherId,
        updateTeacherBasicInfoInput,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} teacherId
   * @param {UpdateTeacherEmailByTeacherId} updateTeacherEmailByTeacherId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerUpdateTeacherEmailByTeacherId(
    organizationKey: string,
    teacherId: string,
    updateTeacherEmailByTeacherId: UpdateTeacherEmailByTeacherId,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerUpdateTeacherEmailByTeacherId(
        organizationKey,
        teacherId,
        updateTeacherEmailByTeacherId,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} teacherId
   * @param {UpdateTeacherLanguageSkillInput} updateTeacherLanguageSkillInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerUpdateTeacherLanguageSkillByTeacherId(
    organizationKey: string,
    teacherId: string,
    updateTeacherLanguageSkillInput: UpdateTeacherLanguageSkillInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerUpdateTeacherLanguageSkillByTeacherId(
        organizationKey,
        teacherId,
        updateTeacherLanguageSkillInput,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} teacherId
   * @param {UpdateTeacherBasicPartialInfo} updateTeacherBasicPartialInfo
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerUpdateTeacherNickNameByTeacherId(
    organizationKey: string,
    teacherId: string,
    updateTeacherBasicPartialInfo: UpdateTeacherBasicPartialInfo,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerUpdateTeacherNickNameByTeacherId(
        organizationKey,
        teacherId,
        updateTeacherBasicPartialInfo,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} teacherId
   * @param {UpdateTeacherQualificationInput} updateTeacherQualificationInput
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerUpdateTeacherQualificationByTeacherId(
    organizationKey: string,
    teacherId: string,
    updateTeacherQualificationInput: UpdateTeacherQualificationInput,
    options?: any
  ) {
    return APIApiFp(this.configuration)
      .organizationsControllerUpdateTeacherQualificationByTeacherId(
        organizationKey,
        teacherId,
        updateTeacherQualificationInput,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {string} organizationKey
   * @param {string} learnerId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public organizationsControllerWithdrawLearner(organizationKey: string, learnerId: string, options?: any) {
    return APIApiFp(this.configuration)
      .organizationsControllerWithdrawLearner(organizationKey, learnerId, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public sandboxControllerCognitoSandbox(options?: any) {
    return APIApiFp(this.configuration)
      .sandboxControllerCognitoSandbox(options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public sandboxControllerMailSandbox(options?: any) {
    return APIApiFp(this.configuration)
      .sandboxControllerMailSandbox(options)
      .then(request => request(this.axios, this.basePath));
  }
}
