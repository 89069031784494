import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import theme from './theme';
import './types';
import { ChatProvider } from './components/ChatProvider';
import { ParticipantProvider } from './components/ParticipantProvider';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';
import {
  COOKIE_DOMAIN,
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  REACT_APP_COGNITO_USER_POOL_ID,
  REACT_APP_IS_DEMO_MODE,
} from './configs';
import DemoRoutes from './components/Router/DemoRoutes/DemoRoutes';
import KokokaRoutes from './components/Router/KokokaRoutes/KokokaRoutes';
import { Amplify } from 'aws-amplify';

Amplify.configure({
  region: REACT_APP_COGNITO_REGION,
  userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  Auth: {
    cookieStorage: {
      domain: COOKIE_DOMAIN,
      secure: true,
    },
  },
});
export const VideoApp = () => {
  const { error, setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <ParticipantProvider>
        <ChatProvider>
          <App />
        </ChatProvider>
      </ParticipantProvider>
    </VideoProvider>
  );
};

export const ReactApp = () => {
  const Routes = REACT_APP_IS_DEMO_MODE ? DemoRoutes : KokokaRoutes;
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <UnsupportedBrowserWarning>
        <Router>
          <AppStateProvider>
            <Routes />
          </AppStateProvider>
        </Router>
      </UnsupportedBrowserWarning>
    </MuiThemeProvider>
  );
};

ReactDOM.render(<ReactApp />, document.getElementById('root'));
