import { useCallback } from 'react';
import { serverApi, requestServerApi } from '../../api/api';
import { RoomType } from '../../types';
import { IDENTITY_SUFFIX } from '../../constants';

// export function getTwilioAccessToken(
//   organization_key: string,
//   id: string,
//   roomName: string
// ): Promise<{ data: string }> {
//   return new Promise((resolve, reject) => {
//     const response = serverApi
//       .organizationsControllerGetTwilioAccessToken(organization_key, id, roomName)
//       .catch(e => null);
//   });
// }

export function getTwilioAccessToken(
  userid: string,
  organization_key: string,
  identity: string,
  roomName: string
): Promise<{ data: string }> {
  const response = requestServerApi<any>(() =>
    serverApi
      .organizationsControllerGetTwilioAccessToken(
        organization_key,
        identity + `${IDENTITY_SUFFIX}${userid}`,
        userid,
        roomName
      )
      .catch(e => null)
  );
  return response;
}

export default function useKokokaAuth() {
  const getTokenKokoka = useCallback((name: string, room: string, organization_key: string, userId: string): Promise<{
    room_type: RoomType;
    token: string;
  }> => {
    return getTwilioAccessToken(userId, organization_key, name, room).then(res => {
      if (res.data) {
        return {
          room_type: 'group',
          token: res.data,
        };
      } else {
        return Promise.reject(new Error('token error'));
      }
    });
  }, []);

  return { getTokenKokoka };
}
