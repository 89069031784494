import React from 'react';
import { Message } from '@twilio/conversations';
import MessageInfo from './MessageInfo/MessageInfo';
import MessageListScrollContainer from './MessageListScrollContainer/MessageListScrollContainer';
import TextMessage from './TextMessage/TextMessage';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { msgObj } from '../../ChatProvider';
import { LocalParticipant } from 'twilio-video';
import dayjs from 'dayjs';

interface MessageListProps {
  messages: Message[] | msgObj[];
}

const getFormattedTime = (message?: Message) =>
  message?.dateCreated?.toLocaleTimeString('en-us', { hour: 'numeric', minute: 'numeric' }).toLowerCase();
function TwilioMessages({ messages, localParticipant }: { messages: Message[]; localParticipant: LocalParticipant }) {
  return (
    <>
      {messages.map((message, idx) => {
        const time = getFormattedTime(message)!;
        const previousTime = getFormattedTime(messages[idx - 1]);

        // Display the MessageInfo component when the author or formatted timestamp differs from the previous message
        const shouldDisplayMessageInfo = time !== previousTime || message.author !== messages[idx - 1]?.author;

        const isLocalParticipant = localParticipant.identity === message.author;

        return (
          <React.Fragment key={message.sid}>
            {shouldDisplayMessageInfo && (
              <MessageInfo author={message.author!} isLocalParticipant={isLocalParticipant} dateCreated={time} />
            )}
            {message.type === 'text' && <TextMessage body={message.body!} isLocalParticipant={isLocalParticipant} />}
          </React.Fragment>
        );
      })}
    </>
  );
}
function KokokaMessage({ messages, localParticipant }: { messages: msgObj[]; localParticipant: LocalParticipant }) {
  return (
    <>
      {messages.map((message, idx) => {
        const time = dayjs(message.dateTime).format('HH:mm:ss');
        const previousTime = dayjs(message.dateTime).format('HH:mm:ss');

        // Display the MessageInfo component when the author or formatted timestamp differs from the previous message
        const shouldDisplayMessageInfo = time !== previousTime || message.username !== messages[idx - 1]?.username;

        const isLocalParticipant = localParticipant.identity === message.username;
        return (
          <React.Fragment key={idx}>
            {shouldDisplayMessageInfo && (
              <MessageInfo author={message.username!} isLocalParticipant={isLocalParticipant} dateCreated={time} />
            )}
            <TextMessage body={message.messageContent!} isLocalParticipant={isLocalParticipant} />
          </React.Fragment>
        );
      })}
    </>
  );
}
export default function MessageList({ messages }: MessageListProps) {
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;

  const isTwilioMessage = messages.some(m => m instanceof Message);
  return (
    <MessageListScrollContainer messages={messages}>
      {isTwilioMessage && <TwilioMessages localParticipant={localParticipant} messages={messages as Message[]} />}
      {!isTwilioMessage && <KokokaMessage localParticipant={localParticipant} messages={messages as msgObj[]} />}
    </MessageListScrollContainer>
  );
}
