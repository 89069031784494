import { useCallback, useEffect, useState } from 'react';
import { requestServerApi, serverApi } from '../../api/api';
import { REACT_APP_IS_DEMO_MODE } from '../../configs';
import { useAppState } from '../../state';
import { GetLessonByRoomId } from '../../api/types/GetLessonByRoomId';

interface Lesson {
  organization_key: string;
  roomName: string;
}
export default function useLesson({ organization_key, roomName }: Lesson) {
  // FIXME 型が定義されたら変更
  const [lesson, setLesson] = useState<any>(null);
  const { setLessonInfo, lessonInfo } = useAppState();

  useEffect(() => {
    if (REACT_APP_IS_DEMO_MODE) {
      return;
    }
    if (!organization_key || !roomName) {
      return;
    }
    const request = async () => {
      const response = await requestServerApi<any>(() =>
        serverApi.organizationsControllerGetLessonByRoomId(organization_key, roomName)
      );
      console.log(response);
      try {
        if (response.data.data && Object.keys(response.data.data[0]).length > 0) {
          setLesson(response.data.data[0]);
          setLessonInfo(old => {
            return { ...old, detail: response.data.data[0] as GetLessonByRoomId };
          });
        }
      } catch (error) {
        console.error(error);
        // FIXME エラーログ
      }
    };
    request().catch(e => console.error('useLesson', { organization_key, roomName }, e));
  }, [organization_key, roomName, setLessonInfo]);

  const getLessonDatetimeExact = useCallback(() => {
    return (timeHHMM: string) => {
      return new Date(
        `${lesson.lesson_day.substring(0, 4)}/${lesson.lesson_day.substring(4, 6)}/${lesson.lesson_day.substring(
          6
        )} ${timeHHMM.substring(0, 2)}:${timeHHMM.substring(2, 4)}:00 +0900`
      );
    };
  }, [lesson]);
  const getLessonStartDatetimeExact = useCallback(() => {
    return getLessonDatetimeExact()(lesson.start_time);
  }, [lesson, getLessonDatetimeExact]);
  const getLessonEndDatetimeExact = useCallback(() => {
    return getLessonDatetimeExact()(lesson.end_time);
  }, [lesson, getLessonDatetimeExact]);
  return { lesson, getLessonStartDatetimeExact, getLessonEndDatetimeExact };
}
