export const IS_PRD = process.env.NODE_ENV === 'production';
export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_STG = (process.env.NODE_ENV as 'staging') === 'staging';

// 文字列として扱う設定値はここで定義
export const {
  REACT_APP_COGNITO_USER_POOL_ID = 'ap-northeast-1_j91qzTpai',
  REACT_APP_COGNITO_USER_POOL_CLIENT_ID = '1ldibirmgncod0gmo0c62fo1ai',
  REACT_APP_COGNITO_REGION = 'ap-northeast-1',
  REACT_APP_API_BASE_URL = 'https://stg.biz-api.kurashi-study.net',
  //REACT_APP_CHAT_WEBSOCKET_URL = 'wss://0pc1o1vynl.execute-api.ap-northeast-1.amazonaws.com/dev',
  REACT_APP_CHAT_WEBSOCKET_URL = 'wss://bvb12btc0a.execute-api.ap-northeast-1.amazonaws.com/stg',
} = process.env;

export const COOKIE_DOMAIN = IS_DEV ? 'localhost' : process.env.REACT_APP_PARENT_URL!.match(/(?<=https:\/\/).*/)![0];

export const REACT_APP_LEARNER_SUBSCRIPTION_URL = IS_DEV ? 'https://localhost:3003/subscription' : process.env.REACT_APP_PARENT_URL +'subscription';

/** デフォルトのビデオ通話時間（分） */
export const REACT_APP_DEFAULT_VIDEO_CALL_TIME = process.env.REACT_APP_DEFAULT_VIDEO_CALL_TIME
  ? parseInt(process.env.REACT_APP_DEFAULT_VIDEO_CALL_TIME)
  : 0;

/** デモモードを有効にするか */
export const REACT_APP_IS_DEMO_MODE = process.env.REACT_APP_IS_DEMO_MODE
  ? JSON.parse(process.env.REACT_APP_IS_DEMO_MODE)
  : false;

export const REACT_APP_RECORDING_IS_ENABLED = process.env.REACT_APP_RECORDING_IS_ENABLED
  ? JSON.parse(process.env.REACT_APP_RECORDING_IS_ENABLED)
  : false;

/** 設定可能項目の有効化 */
export const REACT_APP_IS_ENABLE_ALL_SETTIMGS = process.env.REACT_APP_IS_ENABLE_ALL_SETTIMGS
  ? JSON.parse(process.env.REACT_APP_IS_ENABLE_ALL_SETTIMGS)
  : false;

interface SETTINGS {
  /** パフォーマンスモニター */
  roomMonitor: boolean;
  stopRecoding: boolean;
  about: boolean;
  galleryView: {
    root?: boolean;
    maxGalleryViewParticipants: boolean;
  };
  displayToast: {
    recoding: boolean;
  };
  connectionSettings: {
    root?: boolean;
    dominantSpeakerPriority: boolean;
    clientTrackSwitchOffControl: boolean;
    trackSwitchOffMode: boolean;
    contentPreferencesMode: boolean;
    mode: boolean;
    maxAudioBitrate: boolean;
  };
}

/** 設定項目可能項目 */
export const ENABLED_SETTINGS: SETTINGS = (() => {
  // 明示的に有効にしない限り、全て無効にする（ここにない設定項目は常に有効にする項目）
  if (REACT_APP_IS_ENABLE_ALL_SETTIMGS) {
    return {
      roomMonitor: true,
      stopRecoding: true,
      about: true,
      galleryView: {
        root: true,
        maxGalleryViewParticipants: true,
      },
      displayToast: {
        recoding: true,
      },
      connectionSettings: {
        root: true,
        dominantSpeakerPriority: true,
        clientTrackSwitchOffControl: true,
        trackSwitchOffMode: true,
        contentPreferencesMode: true,
        mode: true,
        maxAudioBitrate: true,
      },
    };
  } else {
    return {
      roomMonitor: false,
      stopRecoding: false,
      about: false,
      galleryView: {
        maxGalleryViewParticipants: false,
      },
      displayToast: {
        all: false,
        recoding: false,
      },
      connectionSettings: {
        dominantSpeakerPriority: false,
        clientTrackSwitchOffControl: false,
        trackSwitchOffMode: false,
        contentPreferencesMode: false,
        mode: false,
        maxAudioBitrate: false,
      },
    };
  }
})();

// check
if (!REACT_APP_RECORDING_IS_ENABLED && process.env.NODE_ENV === 'production') {
  throw Error('本番ではレコーディングを有効にしてください。');
}

export const LESSON_ENTRY_LOG_TYPE = {
  TEACHER_JOINED_LESSON: '1',
  LEARNER_JOINED_LESSON: '2',
} as const;
