import React, { useEffect } from 'react';
import { makeStyles, Typography, Grid, Button, Theme, Hidden, Switch, Tooltip } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useKrispToggle } from '../../../hooks/useKrispToggle/useKrispToggle';
import SmallCheckIcon from '../../../icons/SmallCheckIcon';
import InfoIconOutlined from '../../../icons/InfoIconOutlined';
import { LESSON_ENTRY_LOG_TYPE, REACT_APP_IS_DEMO_MODE } from '../../../configs';
import useIsRoomEntry from '../../../hooks/useIsRoomEntry/useIsRoomEntry';
import { getToken as getApiToken, requestServerApi, serverApi } from '../../../api/api';
import useLesson from '../../../hooks/useLesson/useLesson';
import LanguageSelector from '../../LanguageSelector/LanguageSelector';
import useEventLogger from '../../../hooks/useEventLogger/useEventLogger';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    paddingRight: '2em',
    marginBottom: '2em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
  toolTipContainer: {
    display: 'flex',
    alignItems: 'center',
    '& div': {
      display: 'flex',
      alignItems: 'center',
    },
    '& svg': {
      marginLeft: '0.3em',
    },
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomName: string;
  organization_key?: string;
  userId?: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({
  name,
  roomName,
  organization_key,
  userId,
  setStep,
}: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching, isKrispEnabled, isKrispInstalled, getTokenKokoka, lessonInfo } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const { toggleKrisp } = useKrispToggle();
  const { isNotRoomEntry } = useIsRoomEntry();
  const { lesson } = useLesson({ organization_key: organization_key ?? '', roomName });
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting || isNotRoomEntry;
  const Lang = LanguageSelector();
  console.log(isFetching, isAcquiringLocalTracks, isConnecting, isNotRoomEntry);
  const { sendInfoLog } = useEventLogger();
  useEffect(() => {
    sendInfoLog({
      message: '入室条件確認',
      payload: { disableButtons, isFetching, isAcquiringLocalTracks, isConnecting, isNotRoomEntry },
    });
  }, [isFetching, isAcquiringLocalTracks, isConnecting, isNotRoomEntry]);
  const handleJoin = async () => {
    const apiToken = await getApiToken();
    sendInfoLog({
      message: 'JOIN',
      payload: {
        organization_key,
        userId,
        name,
        roomName,
        apiToken,
      },
    });
    if (REACT_APP_IS_DEMO_MODE) {
      getToken(name, roomName).then(({ token }) => {
        videoConnect(token);
        chatConnect(apiToken);
      });
    } else {
      if (organization_key && userId) {
        await getTokenKokoka(name, roomName, organization_key, userId).then(({ token }) => {
          videoConnect(token);
          chatConnect(apiToken);
        });
        await requestServerApi(async () => {
          serverApi.organizationsControllerCreateLessonEntryRecord(
            'kokoka',
            lessonInfo.type === 'learner'
              ? LESSON_ENTRY_LOG_TYPE.LEARNER_JOINED_LESSON
              : LESSON_ENTRY_LOG_TYPE.TEACHER_JOINED_LESSON,
            { lesson: await lesson }
          );
        });
      }
    }
  };

  const handleCancel = () => {
    if (REACT_APP_IS_DEMO_MODE) {
      setStep(Steps.roomNameStep);
    } else {
      window.close();
    }
  };

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            {Lang.KT_0100_13}
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        {REACT_APP_IS_DEMO_MODE ? <>Join {roomName}</> : <>{Lang.KT_0100_23}</>}
      </Typography>

      <Grid container justifyContent="center">
        <Grid item md={7} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
              <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
              <SettingsMenu mobileButtonClass={classes.mobileButton} />
            </Hidden>
          </div>
        </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <Grid container direction="column" justifyContent="space-between" style={{ alignItems: 'normal' }}>
            <div>
              <Hidden smDown>
                <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
                <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
              </Hidden>
            </div>
          </Grid>
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          {isKrispInstalled && (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ marginBottom: '1em' }}
            >
              <div className={classes.toolTipContainer}>
                <Typography variant="subtitle2">Noise Cancellation</Typography>
                <Tooltip
                  title="Suppress background noise from your microphone"
                  interactive
                  leaveDelay={250}
                  leaveTouchDelay={15000}
                  enterTouchDelay={0}
                >
                  <div>
                    <InfoIconOutlined />
                  </div>
                </Tooltip>
              </div>

              <FormControlLabel
                control={
                  <Switch
                    checked={!!isKrispEnabled}
                    checkedIcon={<SmallCheckIcon />}
                    disableRipple={true}
                    onClick={toggleKrisp}
                  />
                }
                label={isKrispEnabled ? 'Enabled' : 'Disabled'}
                style={{ marginRight: 0 }}
                // Prevents <Switch /> from being temporarily enabled (and then quickly disabled) in unsupported browsers after
                // isAcquiringLocalTracks becomes false:
                disabled={isKrispEnabled && isAcquiringLocalTracks}
              />
            </Grid>
          )}
          <Divider />
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <Grid container direction="row" alignItems="center" style={{ marginTop: '1em' }}>
            <Hidden smDown>
              <Grid item md={7} sm={12} xs={12}>
                <SettingsMenu mobileButtonClass={classes.mobileButton} />
              </Grid>
            </Hidden>

            <Grid item md={5} sm={12} xs={12}>
              <div className={classes.joinButtons}>
                <Button variant="outlined" color="primary" onClick={handleCancel}>
                  {/* Cancel */}
                  {Lang.KT_0100_29}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  data-cy-join-now
                  onClick={handleJoin}
                  disabled={disableButtons}
                >
                  {Lang.KT_0100_05}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
